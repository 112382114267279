import { useState, useEffect } from 'react'
import useDashboardState from '../../utils/useDashboardContext'
import VideoPlayer from '../Video/VideoPlayer'
import AudioPlayer from '../Audio/AudioPlayer'
import checkValidAudioFile from '../../utils/checkValidAudioFile'
import './SyllabiDetail.css';

const SyllabiDetail = ({ isInfoOn }) => {
    const { state } = useDashboardState()
    const { selectedSyllabi, selectedLesson } = state

    const [activeVideo, setActiveVideo] = useState(selectedLesson?.v_address)
    const [videoList, setVideoList] = useState([])

    const formatSyllabiDescription = () => {

        const newDescription = selectedSyllabi.description.replaceAll('&nbsp;', ' ')

        return newDescription
    }
    
    const renderVideoTabs = () => {
        if (videoList.length <= 1) return
        return (
            <div style={{ display: 'flex', gap: '1rem', marginBlock: '0.5rem' }}>
                {videoList.map((video, index) => (
                    <div className='Video__Link' onClick={() => setActiveVideo(video)}>Video {index + 1}</div>
                ))}
            </div>
        )
    }

    useEffect(() => {
        setActiveVideo(selectedLesson?.v_address)
        setVideoList([selectedLesson?.v_address, selectedLesson?.video2, selectedLesson?.v_address3, selectedLesson?.v_address4].filter(n => n))
    }, [selectedLesson])

    return (
        <>
        { selectedSyllabi && isInfoOn &&
            <div className='syllabi_container_style'>
                <div className='syllabi_detail_title'>{selectedSyllabi.title}</div>
                {!selectedSyllabi.description 
                ? <div>No description available.</div>
                : 
                    <div style={detailStyle.teacherNoteBox}>
                        <div style={detailStyle.subContainerStyle} >
                            <p className='syllabi_detail_teachers_notes_container' dangerouslySetInnerHTML={{ __html: formatSyllabiDescription() }} />
                        </div>
                    </div>
                }
            </div>
        }
        {selectedLesson && !isInfoOn &&
            <div className='syllabi_container_style'>
                <div className='syllabi_detail_title'>
                    {selectedLesson?.v_name}
                </div>
                {activeVideo &&
                 <div className='syllabi_video_container'>
                    <VideoPlayer 
                        url={activeVideo}
                    />
                </div>}
                {renderVideoTabs()}
                <div className='syllabi_teacher_note_box'>
                    {selectedLesson && selectedLesson?.audio && checkValidAudioFile(selectedLesson?.audio) &&
                        <div style={detailStyle.subContainerStyle}>
                            <p style={detailStyle.subTitle}>Teachers Music</p>
                            {selectedLesson?.audio && <AudioPlayer audioFile={selectedLesson?.audio} />}
                        </div>
                    }
                    <div style={detailStyle.subContainerStyle} >
                        <p style={detailStyle.subTitle}>Teachers Notes</p>
                        <p style={detailStyle.noteStyle} className='Teachers__Notes__Container' dangerouslySetInnerHTML={{__html:selectedLesson?.notes}} />
                    </div>
                </div>
            </div>
        }
        </>
    )
}

export default SyllabiDetail

const detailStyle = {
    syllabiDetailBox: {
        paddingLeft: '2em',
        width: '65%',
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    syllabiDetailTitle: {
        fontSize: '2vw',
        margin: '0.5em 0',
        fontWeight: 'bold',
    },
    syllabiDetailDesc: {
        fontSize: '0.85vw',
        width: '70%'
    },
    containerStyle: {
        marginLeft: '0.8em',
        width: '68%',
        height: '80vh', //63vh mobile
        overflowY: 'scroll',
        padding: '0 1.5rem',
        paddingBottom: '3rem'
    },
    syllabiDescriptionStyle: {
        flexGrow: '1',
        width: '100%',
        wordWrap: 'wrap',
        overflowY: 'auto',
        paddingBottom: '3rem',
    },
    lessonTitle: {
        fontSize: '1.6vw', 
        marginBottom: '0.9vw', 
        fontWeight: 'bold',
    },
    videoContainer: {
        width: '100%',
    },
    teacherNoteBox: {
        width: '100%', 
        // display: 'flex',
    },
    subContainerStyle: {
        width: '100%',
        // paddingBottom: '1rem'
    },
    subTitle: {
        fontWeight: '600',
        margin: '0.6em 0',
        fontSize: '1.5em',
    },
    noteStyle: {
        margin: '0',
        width: '100%',
        height: '100%',
        paddingRight: '15px',
        paddingBottom: '170px',
    },
}