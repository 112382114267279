export const Discount = [
    {
        period: 'Monthly',
        discount: 'Base rate',
        price: 0
    },{
        period: 'Quarterly',
        discount: '10% off',
        price: 10
    },{
        period: 'Yearly',
        discount: '20% off',
        price: 20
    }
]