import { Stack, Avatar } from "@mui/material";
import { baseURL } from "../../utils/axios";
import useTeacherState from "../../utils/useTeacherContext";
import useDashboardState from "../../utils/useDashboardContext";
import Account from "../../pages/Account/Account";
import MyLibrary from "../../pages/MyLibrary/MyLibrary";
import Studio from "../../pages/Studio/Studio";
import "./DashboardDrawer.css";
import ResponsiveDrawer from "../ResponsiveDrawer";
import React, { useEffect } from "react";

export default function DashboardDrawer() {
  const { teacherState } = useTeacherState();
  const { state } = useDashboardState();
  const [isOpen, setIsOpen] = React.useState(false);
  const { selectedTitle, selectedSyllabi, selectedGenre, selectedLesson } = state

  useEffect(() => {
    if (selectedSyllabi || selectedGenre || selectedLesson) {
      setIsOpen(false);
    }
  }, [selectedGenre, selectedLesson, selectedSyllabi]);


  return (
    <div className="dashboard_drawer_mobile">
      <ResponsiveDrawer anchor="left" isOpen={isOpen} setIsOpen={setIsOpen}
      >
        <div>
          <div className="menu_container">
            {selectedTitle === "library" && <MyLibrary />}
            {selectedTitle === "studio" && <Studio />}
            {selectedTitle === "user" && <Account />}
          </div>

          <div className="icon_avatar">
            <Stack direction="row">
              <Avatar
                sx={{ background: "#F5D900" }}
                alt="L"
                src={
                  baseURL +
                  "file/aws_download?filename=" +
                  teacherState.teacher.avatar
                }
              />
              <div className="avatar_details_container">
                <p className="avatar_text">{teacherState?.teacher?.name}</p>
                <p className="avatar_text">{teacherState?.teacher.email}</p>
              </div>
            </Stack>
          </div>
        </div>
      </ResponsiveDrawer>
    </div>
  );
}
