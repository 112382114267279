
interface Props {
  className?: string;
  img: string;
}

const AtodImg = ({className, img}: Props) => {

  return(
      <div className={className}>
        <img className={`imgStyle ${className}`} src={img} alt='ATOD Logo'/>
      </div>
  )
}

export default AtodImg;