import React, { useEffect, useState } from 'react'
import PaymentMethod from './Subscription/PaymentMethod'
import SubscPeriod from './Subscription/SubscPeriod'
import AtodButton from './AtodButton'
import { useHistory } from 'react-router-dom'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import Instance from '../utils/axios'
import useTeacherState from '../utils/useTeacherContext'
import { discountTypeConverter } from '../utils/discountTypeConverter'
import genreListFormatter from '../utils/genreListFormatter'

const Checkout = ({ discount, setDiscount, selectedPacks }) => {

    const stripe = useStripe()
    const elements = useElements()

    const { teacherState, teacherDispatch } = useTeacherState()
    const { teacher } = teacherState
  
    const [ errorMessage, setErrorMessage ] = useState(null)
    const [ succeededMessage, setSucceededMessage ] = useState(null)
    const [ creditCardId, setCreditCardId ] = useState(null)
    const [ isLoading, setIsLoading ] = useState(false)
    const [ submit, setSubmit ] = useState(false)
    const [ isDisabled, setIsDisabled ] = useState(false)

    const [ cardName, setCardName ] = useState(null)
    const [ cardDetail, setCardDetail ] = useState(false)

    const history = useHistory()

    useEffect(() => {
        cardDetail && cardName && createPaymentMethod()
    }, [cardDetail])

    const createPaymentMethod = async () => {
        if (!stripe || !elements) return
        setErrorMessage(null)
        setSucceededMessage(null)
        try {
            const result = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),
                billing_details: {
                    name: cardName
                }
            })
            if (result.error) {
                submit && setErrorMessage('There was an error processing your payment details, please double check your details and try again')
            } else {
                result.paymentMethod && setCreditCardId(result.paymentMethod.id)
                setSucceededMessage('Credit card information has been processed')
            }   
        } catch (error) {
            submit && setErrorMessage('There was an error processing your payment details, please double check your details and try again')
        }
    }

    const genrePackSubmit = async () => {
        if (!creditCardId || !teacherState ) return
        setIsLoading(true)

        const signupData = {
            email: teacher.email,
            pm_id: creditCardId,
            t_id: teacher.id,
            dances: genreListFormatter(selectedPacks),
            type: discountTypeConverter(discount)
        }

        try {
            const result = await Instance.post('/teacherStripe/createSignUpSubscription', signupData)
            if (result.status === 200) {
                teacherDispatch({ type: 'setTeacher', data: {...teacherState.teacher, dance: result.data, customerId: creditCardId} })
                history.push({ pathname: '/dashboard' })
            }
        } catch (e) {
            setErrorMessage('Please try again later')
            console.log(e.response)
        } finally {
            setIsLoading(false)
        }
        setIsLoading(false)
        setIsDisabled(true)
        setSubmit(true)
    }

    const billableStartDateTimeStamp = new Date().getTime() + (86400000 * 14)
    const billableStartDate = new Date(billableStartDateTimeStamp).toDateString()

    return (
    <>
        <PaymentMethod 
            errorMessage={errorMessage} 
            succeededMessage={succeededMessage} 
            activeCardDetail={setCardDetail} 
            activeCardName={setCardName}
        />
        <div style={checkoutStyle.trialBox}>
            <div style={checkoutStyle.trialTitle}>14 Day Free Trial!</div>
            <p style={checkoutStyle.trialDesc}>Your subscription will start with a 14 day free trial.</p>
        </div>
        <div style={checkoutStyle.borderHorizontal}></div>
        <div style={{width: '37em'}}>
            <h3 style={checkoutStyle.sectionTitle}>Subscription billing period</h3>
            <p style={{...checkoutStyle.section_instruction, marginBottom: '0.6em'}}>Select when you would like your subscription to be billed.</p>
            <SubscPeriod discount={discount} setDiscount={setDiscount} />
        </div>
        <div style={{...checkoutStyle.trialBox, padding: '0.6vw 1.8vw'}}>
            <div style={checkoutStyle.trialTitle}>Billing Commencement</div>
            <p style={checkoutStyle.trialDesc}>Your first billable period will begin on <span style={{ fontWeight: 700 }}>{billableStartDate}</span> for the amount outlined in your studio plan.</p>
        </div>
        <AtodButton 
            disabled={!stripe} 
            text='Finish &amp; Pay' 
            margin='25px 0' 
            onClick={genrePackSubmit}
            isLoading={isLoading}
        />
    </>
    )
}

export default Checkout

const checkoutStyle = {
    sectionTitle: {
        color: '#2066B2',
        marginTop: '0.8vw',
        fontSize: '1vw',
    },
    section_instruction: {
        color: '#414141',
        fontSize: '0.8vw',
    },
    trialBox: {
        width: '24vw',
        height: 'auto',
        background: '#D7DFF4',
        borderRadius: '10px',
        margin: '10px 0',
        textAlign: 'center',
        padding: '0.6vw 5vw',
    },
    borderHorizontal: {
        borderBottom: '1px solid #E3E3E3',
        width: '80%',
        margin: '20px auto',
    },
    trialTitle: {
        fontSize: '1.3vw',
        fontWeight: 'bold',
        color: '#2066B2'
    },
    trialDesc: {
        fontSize: '1vw',
        color: '#414141'
    }
}