import './Subscription.css'
import { Discount } from '../../assets/Constant/Discounts'

const SubscPeriod = ({ discount, setDiscount }) => {

    const addDiscountPrice = (subscription) => {
        setDiscount({ period: subscription.period, price: subscription.price })
    }

    return(
        <div className='period_container'>
            {Discount.map((subscription, index) => {
                return(
                    <div className={discount.period === subscription.period ? 'period_card disabled' : 'period_card'} onClick={() => addDiscountPrice(subscription)} key={index}>
                        <div className='period_text'>{subscription.period}</div>
                        <div className='period_desc'>{subscription.discount}</div>
                    </div>
                )
            })}
        </div>
    )
}

export default SubscPeriod