import { useState } from 'react'
import SelectList from '../../components/SelectList/SelectList'
import AtodButton from '../../components/AtodButton'
import AtodModal from '../../components/AtodModal'
import Sidebar from '../../components/Dashboard/Sidebar'
import MyAccount from './MyAccount'
import SubscriptionSettings from './SubscriptionSettings'
import { Redirect } from 'react-router'
import { useLocation } from 'react-router'
import './Account.css'

const Account = () => {
    const location = useLocation()
    const [activeTab, setActiveTab] = useState(location?.state?.startTab ?? 'My Account')
    const accountTabNames = ['My Account', 'Update Password', 'Subscription Settings', 'Terms & Conditions']
    const title = 'Account'
    const [hasActiveTab, setHasActiveTab] = useState(false);

    const [ open, setOpen ] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const renderAccountContent = () => {
        switch (activeTab) {
            case 'My Account':              return <MyAccount/>
            case 'Update Password':         return <Redirect to={'update_password'} />
            case 'Subscription Settings':   return <SubscriptionSettings/>
        }
    }

    const isMobileView = window.innerWidth <= 768;

    return (
        <div className='account_container'>
            
            <Sidebar page='Account' />

                {(!isMobileView || (isMobileView && !hasActiveTab)) && <SelectList tabNames={accountTabNames} title={title}
                activeTab={activeTab} setActiveTab={(tabName) => {
                    setActiveTab(tabName);
                    setHasActiveTab(true);
                }}>

                    <AtodButton
                        text='Logout'
                        width='90%'
                        bg='#E8323E'
                        hc='#E8323E'
                        margin='0 auto'
                        padding='15px'
                        onClick={handleOpen}
                    />

                </SelectList>}

            {(!isMobileView || (isMobileView && hasActiveTab)) && 

            <div className='account_container_style'>
                {renderAccountContent()}
            </div>
            }

            <AtodModal
                open={open}
                onClose={handleClose}
                modalBtn='logout'
            />
        </div>
    )
}

export default Account;
