import React, { useReducer } from 'react'
import Sidebar from '../../components/Dashboard/Sidebar'
import DashboardContentLists from '../../components/Dashboard/DashboardContentLists'
import DashboardMainContent from '../../components/Dashboard/DashboardMainContent'
import DashboardReducer from '../../utils/Reducer/DashboardReducer'
import './Dashboard.css'
import DashboardDrawer from '../../components/Dashboard/DashboardDrawer'

export const DashboardContext = React.createContext();

const initialState = {
    selectedTitle: 'library'
}

const Dashboard = () => {
    const [state, dispatch] = useReducer(DashboardReducer, initialState)
    return (
        <DashboardContext.Provider value={{ state, dispatch }}>
            <div className='dashboard_container_style'>
                <Sidebar page='Dashboard' isMobileView={true} />
                <DashboardDrawer />
                <DashboardContentLists /> 
                 <DashboardMainContent  />
            </div>
        </DashboardContext.Provider>
    )
}

export default Dashboard
