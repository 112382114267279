import GenreCard from '../GenreCard/GenreCard'
import { DefaultGenrePacks } from '../../assets/Constant/GenrePacks'
import './GenrePacks.css';

const GenrePacks = ({ selectedPacks, unselectedPacks, setSelectedPacks, setUnselectedPacks }) => {

    const addPack = (pack) => {
        if (pack.genre === 'Full Suite') {
            setSelectedPacks([...DefaultGenrePacks])
            setUnselectedPacks([])
            return
        }

        const newSelected = [...selectedPacks].concat(pack)
        const newUnselected = [...unselectedPacks].filter((p) => p !== pack)
        setSelectedPacks(newSelected)
        setUnselectedPacks(newUnselected)
    }

    const removePack = (pack) => {
        if (pack.genre !== 'Full Suite' && selectedPacks.includes(DefaultGenrePacks[0])) return
        if (pack.genre === 'Full Suite') {
            setSelectedPacks([])
            setUnselectedPacks([...DefaultGenrePacks])
            return
        }

        const newSelected = [...selectedPacks].filter((p) => p !== pack)
        const newUnselected = [...unselectedPacks].concat(pack)
        setSelectedPacks(newSelected)
        setUnselectedPacks(newUnselected)
    }

    return (
        <>
            <div className='genre_packs_flexBox'>
                {selectedPacks.map((pack => {
                    return <GenreCard pack={pack} key={pack.genre} handleClick={() => removePack(pack)} selected 
                    disabled={selectedPacks.includes(DefaultGenrePacks[0])}/>
                }))}
             </div>
            <div className='genre_packs_borderStyle'></div>

            <div className='genre_packs_flexBox'>
                {unselectedPacks.map((pack => {
                    return <GenreCard pack={pack} key={pack.genre} handleClick={() => addPack(pack)}/>
                }))}
             </div>
        </>
    )
}

export default GenrePacks

