export const DefaultGenrePacks = [
    {
        genre: 'Full Suite',
        desc: ['All genre specific syllabi’s', 'Teacher assets, videos & audio'],
        price: '50',
        status: 'deselected'
    },{
        genre: 'Jazz',
        desc: ['All genre specific syllabi’s', 'Teacher assets, videos & audio'],
        price: '20',
        status: 'deselected'
    },{
        genre: 'Hip-Hop',
        desc: ['All genre specific syllabi’s', 'Teacher assets, videos & audio'],
        price: '20',
        status: 'deselected'
    },{
        genre: 'Street Beat Tap',
        desc: ['All genre specific syllabi’s', 'Teacher assets, videos & audio'],
        price: '20',
        status: 'deselected'
    },{
        genre: 'Classical Ballet',
        desc: ['All genre specific syllabi’s', 'Teacher assets, videos & audio'],
        price: '20',
        status: 'deselected'
    },{
        genre: 'National Character',
        desc: ['All genre specific syllabi’s', 'Teacher assets, videos & audio'],
        price: '20',
        status: 'deselected'
    },{
        genre: 'Contemporary',
        desc: ['All genre specific syllabi’s', 'Teacher assets, videos & audio'],
        price: '20',
        status: 'deselected'
    },{
        genre: 'Tap',
        desc: ['All genre specific syllabi’s', 'Teacher assets, videos & audio'],
        price: '20',
        status: 'deselected'
    },{
        genre: 'Musical Theatre',
        desc: ['All genre specific syllabi’s', 'Teacher assets, videos & audio'],
        price: '20',
        status: 'deselected'
    },
]