import { useState } from 'react'
import SelectList from '../../components/SelectList/SelectList'
import Sidebar from '../../components/Dashboard/Sidebar'
import StudioGenrePacks from './StudioGenrePacks'
import './Studio.css';

const Studio = () => {
    const studioTabNames = ['Studio Genre Packs']
    const title = 'ATOD Studio'

    const [activeTab, setActiveTab] = useState('Studio Genre Packs')

    const renderStudioContent = () => {
        switch (activeTab) {
            case 'Studio Genre Packs': return <StudioGenrePacks/>
        }
    }

    return (
        <div className='studio_dashboard_container'>

            <Sidebar page='Studio'/>

            <SelectList tabNames={studioTabNames} title={title} 
            activeTab={activeTab} setActiveTab={setActiveTab} />

            <div className='studio_container_style'>
                {renderStudioContent()}
            </div>
        </div>
    )
}

export default Studio
