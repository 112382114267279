import { useState, useEffect } from 'react'
import './Auth.css'
import MainImg from '../../assets/Images/Main_Img.png'
import BackButton from '../../components/BackButton/BackButton'
import AtodTextInput from '../../components/AtodTextInput'
import AtodButton from '../../components/AtodButton'
import { useHistory } from 'react-router-dom'
import { validateUpdatePassword } from '../../utils/validation'
import useTeacherState from '../../utils/useTeacherContext'
import Instance from '../../utils/axios'
import { Alert } from '@mui/material'
import AtodUpdatePasswordImg from '../../components/AtodUpdatePasswordImg/AtodUpdatePasswordImg'

const UpdatePassword = () => {

    const { teacherState } = useTeacherState()


    const [ password, setPassword ] = useState('')
    const [ newPassword, setNewPassword ] = useState('')
    const [ newConfirmPassword, setNewConfirmPassword ] = useState('')
    const [ passwordSubmit , setPasswordSubmit ] = useState(false)
    const [ passwordError, setPasswordError ] = useState('')
    const [ validationMessage, setValidationMessage ] = useState({})
    const [ submit, setSubmit ] = useState(false)
    const [ isLoading, setIsLoading ] = useState(false)
    const [ isSubmitLoading, setIsSubmitLoading ] = useState(false)

    const history = useHistory()

    useEffect(() => {
        if (!submit) return
        validateInput()
    },[ newPassword, newConfirmPassword, submit ])

    const validateInput = () => {
        setSubmit(true)
        const errorMessages = validateUpdatePassword(newPassword, newConfirmPassword)
        setValidationMessage(errorMessages)
        return errorMessages
    }

    const enterPassword = async () => {
        setIsLoading(true)
        try {
            await Instance.post('teacher/login', {
                email: teacherState.teacher.email,
                password
            })
            setIsLoading(false)
            setPasswordSubmit(!passwordSubmit)
        } catch (error) {
            setIsLoading(false)
            if (error.response.status === 400) return setPasswordError('Please enter password')
            else if (error.response.status === 401) return setPasswordError('Password is incorrect')
            else return setPasswordError('An unknown error has occured')
        }
        setIsLoading(false)
    }

    const updatePassword = async () => {
        setIsSubmitLoading(true)
        const message = validateInput()
        if (message === 'clear') {
            try {
                const result = await Instance.put('teacher/updatePassword', {
                    email: teacherState.teacher.email,
                    newPassword
                })
                if (result.status === 200) {
                    alert('Your password has been successfully updated')
                    history.push({pathname: 'login'})
                }
            } catch (error) {
                console.log(error.response)
                setIsSubmitLoading(false)
                alert('An unknown error has occured')
            }
        }
        setIsSubmitLoading(false)
    }

    return(
        <div className='min_width_wrapper'>
            <AtodUpdatePasswordImg img={MainImg}/>
            <div className='right_container'>
                <div className='centralizing'>
                    {!passwordSubmit
                    ? <>
                        <BackButton smaller='smaller' link='dashboard'/>
                        <div className='main_title'>Update Password</div>
                        <h3 className='section_title'>Current Password</h3>
                        <p className='section_instruction'>Enter your current ATOD@Work password.</p>
                        {passwordError ? <Alert severity='error'>{passwordError}</Alert> : ""}
                        <AtodTextInput type='password' label='Password' placeholder='Enter' textInput={setPassword}/>
                        <AtodButton 
                            text='Next' 
                            margin='30px 0' 
                            isLoading={isLoading} 
                            onClick={enterPassword}
                        />
                    </>
                    : <>
                        <BackButton smaller='smaller' onClick={enterPassword} />
                        <div className='main_title'>Update Password</div>
                        <h3 className='section_title'>New Password</h3>
                        <p className='section_instruction'>Enter your new ATOD@Work password.</p>
                        <AtodTextInput 
                            type='password' 
                            label='New Password' 
                            placeholder='Enter' 
                            textInput={setNewPassword}
                            errorMessage={validationMessage.passwordMessage}
                        />
                        <AtodTextInput 
                            type='password' 
                            label='Confirm New Password' 
                            placeholder='Enter' 
                            textInput={setNewConfirmPassword}
                            errorMessage={validationMessage.passwordConfirmMessage}
                        />
                        <AtodButton 
                            text='Next' 
                            margin='50px 0' 
                            isLoading={isSubmitLoading} 
                            onClick={updatePassword}
                        />
                    </>
                    }
                </div>
            </div>
        </div>
    )
}

export default UpdatePassword
