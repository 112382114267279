const discountTypeConverter = (discount) => {
    switch (discount.period) {
        case 'Quarterly':   return 'quarter'
        case 'Monthly':     return 'month'
        case 'Yearly':      return 'year'
    }
}

const reverseTypeConverter = (type) => {
    switch (type) {
        case 'quarter': return 'Quarterly'
        case 'month':   return 'Monthly'
        case 'year':  return 'Yearly'
    }
}

export { discountTypeConverter, reverseTypeConverter}