const genreListFormatter = (genres) => {

    let newArr = []
    let fullFlag = false

    genres.forEach(genre => {
        if (genre.genre === 'Full Suite') fullFlag = true
        newArr.push(genre.genre)
    })
    if (fullFlag) return ['full']
    return newArr
}

export default genreListFormatter