import { useEffect, useState } from 'react'
import LargeCard from '../LargeCard/LargeCard'
import MainTitle from '../MainTitle/MainTitle'
import useDashboardState from '../../utils/useDashboardContext'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import AtodButton from '../AtodButton'
import Instance from '../../utils/axios'
import { useHistory } from 'react-router-dom'
import './GenreContents.css'
import DescriptionModal from '../DescriptionModal/DescriptionModal';

const GenreContents = () => {

    const { state } = useDashboardState()
    const { selectedGenre } = state
    const [ syllabus, setSyllabis ] = useState([])
    const [ openDescriptionModal, setOpenDescriptionModal ] = useState(false);
    const handleClose = () => setOpenDescriptionModal(false);


    const history = useHistory()

    useEffect(() => {
        async function fetchData() {
            try {
                const result = await Instance.get(`/dance/getSyllabus?d_name=${selectedGenre.title}`)
                setSyllabis(result.data)
            } catch (error) {
                console.log(error)
            }
        }
        fetchData()
    }, [selectedGenre])

    const updatePacks = () => {
        history.push({pathname: 'studio'})
    }

    return (
        <div className='genre_content_container'>
            {openDescriptionModal && 
                <DescriptionModal 
                    openModal={openDescriptionModal} 
                    onClose={handleClose} 
                />
            }
            <div className='genre_content_title'>
                <p>{selectedGenre.title}</p>
                <button
                    className='genre_content_description_button'
                    onClick={() => setOpenDescriptionModal(true)}
                >Read Description
                </button>
            </div>
                <MainTitle/>
            <div className='genre_content_card_container'>
                {selectedGenre.availability === 'No Access' &&
                    <div className='no_access_container'>
                        <div className='update_container'>
                            <ArrowForwardIcon className='arrow_icon'/>
                            <div className='update_title'>Upgrade To Access</div>
                            <div className='update_desc'>Your current ATOD Studio plan does not include this Genre. Upgrade to gain access.</div>
                            <AtodButton
                                text= 'Upgrade'
                                margin= '1.3em auto'
                                onClick={updatePacks}
                            />
                        </div>
                    </div>
                }
                <div className='available_container'>
                    <div className='genre_content_flex_container'>
                        { 
                            syllabus.map(syllabi => {
                                return <LargeCard 
                                    syllabi={syllabi} 
                                    key={syllabi.d_id} 
                                    noAccess={selectedGenre.availability === 'No Access' ? true : false}
                                />
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GenreContents;