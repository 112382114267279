import React, { useReducer, useEffect, useState } from 'react'
import './App.css'
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom"
import TeacherReducer from './utils/Reducer/TeacherReducer'
import Login from './pages/Authentication/Login'
import Signup from './pages/Authentication/Signup'
import RetrivePassword from './pages/Authentication/RetrivePassword'
import UpdatePassword from './pages/Authentication/UpdatePassword'
import PickGenrePacks from './pages/Studio/PickGenrePacks'
import Dashboard from './pages/Dashboard/Dashboard'
import Studio from './pages/Studio/Studio'
import Account from './pages/Account/Account'
import Instance from './utils/axios'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

export const TeacherContext = React.createContext()

const stripePromise = loadStripe('pk_live_51IJVciGeFuaOnJVmTPykUIPgWOZFfqiWjQz3zEIejuUIsHnPXTlHbWJSxft07ohxJobu86OCudOaMotSZZRlSBhq00GNNAR97l')

const initialTeacherState = {}

function App() {

  const [ teacherState, teacherDispatch ] = useReducer(TeacherReducer, initialTeacherState)
  const [ loadingUser, setLoadingUser ] = useState(true)
  const { teacher } = teacherState
  const token = localStorage.getItem('ATODTeacherToken')

  const getTeacherData = async () => {
    try {
      const teacherData = await Instance.get('/teacher/me')
      teacherDispatch({ type: 'setTeacher', data: teacherData.data })
      } catch (error) {
      console.log(error)
    } finally {
      setLoadingUser(false)
    }
  }

  useEffect(() => {
    if (!token) {
      setLoadingUser(false)
      teacherDispatch({ type: 'setTeacher', data: null })
      return
    }
    getTeacherData()
  },[])

  function AuthRoute({ component: Component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          teacher
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }
      />
    )
  }

  function AuthRedirectRoute({ component: Component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          teacher
            ? <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />
            : <Component {...props} />
        }
      />
    )
  }

  return (
    <Elements stripe={stripePromise}>
      <TeacherContext.Provider value={{ teacherState, teacherDispatch }}>
        {loadingUser ? '' : 
          <Router>
            <Switch>
              <AuthRedirectRoute exact path='/login' component={Login} />
              <AuthRedirectRoute exact path='/signup' component={Signup}/>
              <AuthRedirectRoute exact path='/retrive_password' component={RetrivePassword}/>
              <AuthRoute exact path='/update_password' component={UpdatePassword}/>
              <AuthRoute exact path='/studio' component={Studio} />
              <AuthRoute exact path='/user' component={Account} />
              <Route exact path='/genre_packs' component={PickGenrePacks}/>
              {/* TEST AS AUTH ROUTE ^ */}
              <AuthRoute exact path='/dashboard' component={Dashboard} />

              <Redirect to='/login' />

            </Switch>
          </Router>
        }
      </TeacherContext.Provider>
    </Elements>
  )
}

export default App
