import { useState, useRef, useEffect } from 'react'
import './AudioPlayer.css'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import {BsDownload} from 'react-icons/bs';

const AudioPlayer = ({ audioFile }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)

  const audioPlayer = useRef()
  const progressBar = useRef()
  const animationRef = useRef()

  useEffect(() => {
    const seconds = Math.floor(audioPlayer.current.duration)
    setDuration(seconds)
    progressBar.current.max = seconds
  }, [audioPlayer?.current?.loadedmetadata, audioPlayer?.current?.readyState, audioPlayer?.current?.duration])

  useEffect(() => {
    setIsPlaying(false)
  },[audioFile])

  const togglePlayPause = () => {
    const prevValue = isPlaying
    setIsPlaying(!prevValue)
    if (!prevValue) {
      audioPlayer.current.play()
      animationRef.current = requestAnimationFrame(whilePlaying)
    } else {
      audioPlayer.current.pause()
      cancelAnimationFrame(animationRef.current)
    }
  }

  const whilePlaying = () => {
    progressBar.current.value = audioPlayer.current.currentTime
    changePlayerCurrentTime()
    animationRef.current = requestAnimationFrame(whilePlaying)
  }

  const changeRange = () => {
    audioPlayer.current.currentTime = progressBar.current.value
    changePlayerCurrentTime()
  }

  const changePlayerCurrentTime = () => {
    progressBar.current.style.setProperty('--played-width', `${progressBar.current.value / duration * 100}%`)
    setCurrentTime(progressBar.current.value)
  }

  const calculateTime = (secs) => {
    const minutes = Math.floor(secs / 60)
    const seconds = Math.floor(secs % 60)
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`
    return `${minutes}:${returnedSeconds}`
  }

  const downloadAudioFile = async (audioFile) => {
    const link = document.createElement("a");
    link.href = audioFile;
    link.setAttribute("download", "audioFile");
    link.setAttribute("target", "_blank");
    link.click();
  };

  return (
    <div className='audio_wrapper'>
      <div className='borderStyle'>
        {/* <embed src={audioFile} ref={audioPlayer} /> */}
        <audio ref={audioPlayer} src={audioFile}></audio>
        <div className='audio_timeBox'>
          <div className='audio_time'>{calculateTime(currentTime)}</div>
          <div className='audio_time'>/</div>
          <div className='audio_time'>{(duration && !isNaN(duration)) ? calculateTime(duration) : '0:00'}</div>
        </div>
        <button onClick={togglePlayPause} className='playPause'>
          {isPlaying 
          ? <PauseIcon className='play_pause_icons'/> 
          : <PlayArrowIcon className='play_pause_icons'/>
          }
        </button>
        <div className='audio_progressBar_box'>
          <input 
            type="range" 
            className='audio_progressBar' 
            defaultValue="0" 
            ref={progressBar} 
            onChange={changeRange} 
          />
        </div>

        <div className='audio_download_icon'>
          <button
          onClick={() => downloadAudioFile(audioFile)}
          >
          <BsDownload />
          </button>
        </div>

      </div>
    </div>
  )
}

export default AudioPlayer;