import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { styled, Button } from "@mui/material";
import useDashboardState from '../../utils/useDashboardContext';
import './DescriptionModal.css';
import CloseIcon from '@mui/icons-material/Close';
import { JazzDescription, BalletDescription, HipHopDescription, ContemporaryDescription, MusicalTheatreDescription, StreetBeatTapDescription, TapDescription, NationalCharacterDescription } from '../GenreDescriptions';


interface Props {
  openModal: boolean;
  onClose: () => void;
}

function DescriptionModal({ openModal, onClose }: Props) {
    const { state } = useDashboardState()
    const { selectedGenre } = state

  const BootstrapDescriptionModal = styled(Modal)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    height: 650,
    border: "1px solid #000",
    overflowX: "auto",
    overflowY: "auto",
    borderRadius: "10px",
    backgroundColor: '#e6e9ff',
  }));

  const renderGenreNotes = () => {
    switch (selectedGenre.title) {
        case 'Jazz':                    return JazzDescription()
        case 'Classical Ballet':        return BalletDescription()
        case 'Hip-Hop':                 return HipHopDescription()
        case 'Contemporary':            return ContemporaryDescription()
        case 'Musical Theatre':         return MusicalTheatreDescription()
        case 'Street Beat Tap':         return StreetBeatTapDescription()
        case 'Tap':                     return TapDescription()
        case 'National Character':      return NationalCharacterDescription() 
    }
}

  return (
    <BootstrapDescriptionModal open={openModal}>
      <Box>
        <div className="description_modal_genre_content_flex_container">
            {selectedGenre &&       
                <div className='description_modal_genre_content'>
                    <div className='description_modal_genre_content_title_style'>
                        {selectedGenre.title}
                    </div>
                  <Button onClick={onClose}>{<CloseIcon className="description_modal_icon"/>}</Button>
                </div>     
            }
            {selectedGenre &&
                <div className='description_modal_main_title_genre_desc'>
                    {renderGenreNotes()}
                </div>
            }
        </div>
      </Box>
    </BootstrapDescriptionModal>
  );
}
export default DescriptionModal;
