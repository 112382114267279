export const DefaultGenres = [
    {
        title: 'Jazz',
        availability: 'No Access'
    },{
        title: 'Hip-Hop',
        availability: 'No Access'
    },{
        title: 'Contemporary',
        availability: 'No Access'
    },{
        title: 'Classical Ballet',
        availability: 'No Access'
    },{
        title: 'Musical Theatre',
        availability: 'No Access'
    },{
        title: 'Street Beat Tap',
        availability: 'No Access'
    },{
        title: 'Tap',
        availability: 'No Access'
    },{
        title: 'National Character',
        availability: 'No Access'
    },
]