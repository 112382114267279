export interface IconBaseProps {
  onClick?: () => void;
  className?: string;
  width?: number;
  height?: number;
}

interface Props extends IconBaseProps {}

function Genre({ className, onClick }: Props) {
  return (
    <div
      className={
        `${
          onClick
            ? "cursor-pointer hover:scale-110 hover:rotate-3 transition-all duration-300 "
            : " "
        }` + className
      }
      onClick={onClick}
    >
      <svg
        id="libaray"
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="19"
        viewBox="0 0 22 19"
      >
        <path
          id="Icon"
          d="M1.123,19A1.131,1.131,0,0,1,0,17.864V6.165A1.13,1.13,0,0,1,1.123,5.029H20.878A1.128,1.128,0,0,1,22,6.165v11.7A1.129,1.129,0,0,1,20.878,19ZM2.534,3.613a.615.615,0,0,1,0-1.23H19.515a.615.615,0,0,1,0,1.23ZM4,1.231a.554.554,0,0,1-.55-.557V.557A.554.554,0,0,1,4,0H17.787a.555.555,0,0,1,.551.557V.674a.555.555,0,0,1-.551.557Z"
        />
      </svg>
    </div>
  );
}

export default Genre;
