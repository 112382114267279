import './LargeCard.css'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import { GenreColorGenerator } from '../../utils/GenreColorGenerator'
import useDashboardState from '../../utils/useDashboardContext'
import { getOnlineImage } from '../../utils/getOnlineImage'
import Instance from '../../utils/axios'

const LargeCard = ({ lesson, genre, syllabi, infoOff, noAccess }) => {
    
    const { dispatch } = useDashboardState()
    const genreColor = (syllabi && GenreColorGenerator(syllabi.d_name)) || (genre && GenreColorGenerator(genre))

    const cardStyles = {
        cardShadow: {
            boxShadow: `inset 8px 0px 0px 0px ${genreColor}, 5px 5px 5px 1px #DEDEDE`,
        },
        iconStyle: {
            fill: genreColor,
        },
    }

    const clickLessonCard = () => {
        infoOff(false)
        dispatch({
            type: 'setSelectedLesson', 
            data: lesson
        })
    }

    const clickGenreCard = async () => {
        try {
            const result = await Instance.get(`/dance/getVideo?d_id=${syllabi.d_id}`)
            if (result.status === 200) {
                const lessons =  result.data.map(({ audio, d_id, notes, v_address, v_id, v_image, v_name, video2, video3, video4 }) => {
                    return {audio, d_id, notes, v_address, v_id, v_image, v_name, video2, video3, video4}
                })
                dispatch({
                    type: 'setSelectedSyllabi',
                    data: {
                        id: syllabi.d_id, 
                        title: syllabi.d_syllabus_name, 
                        genre: syllabi.d_name, 
                        level: syllabi.d_syllabus_level, 
                        image: syllabi.d_syllabus_image,
                        description: syllabi.d_syllabus_description,
                        lessons: [...lessons]
                    }
                })
            }
        } catch (error) {
            if (error.response.status === 403) dispatch({ type: 'setSelectedSyllabi', data: {
                id: syllabi.d_id,
                title: syllabi.d_syllabus_name,
                genre: syllabi.d_name,
                level: syllabi.d_syllabus_level,
                image: syllabi.d_syllabus_image,
                description: syllabi.d_syllabus_description,
                lessons: 'No Videos'
            }})
        }

        dispatch({
            type: 'setSelectedGenre', 
            data: ''
        })
    }

    return (
        <>
            {lesson &&
            <div className='syllabi_card_container' style={cardStyles.cardShadow} onClick={clickLessonCard}>
                <div className='img_box'>
                    {lesson.v_image 
                        ? <img className='syllabi_image' src={getOnlineImage(lesson.v_image)} alt='lesson'/>
                        : <div></div>
                    }
                </div>
                    <div className='syllabi_title'>
                        <div className='syllabi_title_style'>{lesson.v_name}</div>
                    </div>      
            </div>
            }
            {syllabi && 
            <div 
                className='genre_card_container' 
                style={cardStyles.cardShadow} 
                onClick={noAccess ? null : clickGenreCard}
            >
                <div className='img_box'>
                    {syllabi.d_syllabus_image 
                        ? <img className='syllabi_image' src={getOnlineImage(syllabi.d_syllabus_image)} alt='Syllabie' />
                        : <div></div>
                    }
                </div>
                <div className='genre_detail_box'>
                    <div className='genre_title'>
                        <div className='genre_name'>{syllabi.d_syllabus_name}</div>
                    </div>
                </div>
                <div className='arrow_container'>
                    <KeyboardDoubleArrowRightIcon className='arrow_icon' style={{fill: genreColor}}/>
                </div>
            </div>
            }
        </>
    )
}

export default LargeCard
