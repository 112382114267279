import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import React from "react";
import { Button, styled } from "@mui/material";
import useDashboardState from '../../utils/useDashboardContext';
import './SyllabiDescriptionModal.css';
import CloseIcon from '@mui/icons-material/Close';


interface Props {
  openModal: boolean;
  onClose: () => void;
}

function SyllabiDescriptionModal({ openModal, onClose }: Props) {
    const { state } = useDashboardState();
    const { selectedSyllabi } = state;

  const BootstrapDescriptionModal = styled(Modal)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    height: 650,
    border: "1px solid #000",
    overflowX: "auto",
    overflowY: "auto",
    borderRadius: "10px",
    backgroundColor: '#e6e9ff',
  }));

  const formatSyllabiDescription = () => {
    const newDescription = selectedSyllabi.description.replaceAll('&nbsp;', ' ')
    return newDescription
}

  return (
    <BootstrapDescriptionModal open={openModal}>
      <Box>
        <div className="syllabi_description_modal_container">
            <Button onClick={onClose}>{<CloseIcon className="syllabi_description_modal_icon"/>}</Button>
              <div className="syllabi_description_modal_title_style">
                <p 
                  className='syllabi_teachers_notes_container' 
                  dangerouslySetInnerHTML={{ __html: formatSyllabiDescription() }} 
                  />
              </div>
        </div>
      </Box>
    </BootstrapDescriptionModal>
  );
}
export default SyllabiDescriptionModal;
