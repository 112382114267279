import SyllabiContents from '../Syllabi/SyllabiContents'
import GenreContents from '../Genre/GenreContents'
import StudioGenrePacks from '../../pages/Studio/StudioGenrePacks'
import MyAccount from '../../pages/Account/MyAccount'
import SubscriptionSettings from '../../pages/Account/SubscriptionSettings'
import useDashboardState from '../../utils/useDashboardContext'
import { Redirect } from 'react-router-dom'
import AtodLogo from '../../assets/Images/AtodLogo.png'
import './DashboardMainContent.css';
import { GrDocumentDownload } from 'react-icons/gr';

const DashboardMainContent = () => {
    const { state } = useDashboardState()
    const { selectedTitle, selectedSyllabi, selectedGenre, selectedAccountStudio } = state

    const librarySwitch = () => {
        if (selectedSyllabi) return <SyllabiContents />
        else if (selectedGenre) return <GenreContents />
        else return <div className='document_wrapper'>
                <iframe className="document_container" src="https://docs.google.com/document/d/e/2PACX-1vQ_LhJc1plSudhMXGunUED2q6NsmLSjohI4PSqw-Sw9fDIANnujsNDJ5niGofQ1ow/pub?embedded=true" />
            </div>
    }

    const accountStudioSwitch = () => {
        switch (selectedAccountStudio) {
            case 'Studio Genre Packs': return <StudioGenrePacks/>
            case 'My Account': return <MyAccount/>
            case 'Update Password': return <Redirect to={'update_password'}></Redirect>
            case 'Subscription Settings': return <SubscriptionSettings/>
            case 'Terms & Conditions': return <div className='default_main'><img src={AtodLogo} alt='atod main'/></div>
            default: return <div className='default_main'><img src={AtodLogo} alt='atod main'/></div>
        }
    }

    const renderMainContentMobileView = () => {
        return (
            <>
            <div className='main_content_mobile_view'>
                
                    <div className='main_content_mobile_welcome'>
                        <img src={AtodLogo} alt='atod main logo'/>
                        <h2 className='main_content_mobile_view_welcome_title'>Welcome to ATOD@work, our teaching resources at your fingertips!!</h2>
                    </div>
                
                <div className='main_content_mobile_download_text'>
                    <p>Click here to download "The Examination" document.</p>
                        <GrDocumentDownload 
                            className='doc_download_icon' 
                            style={{ marginTop: '20px' }} 
                            id='file_download' 
                            target="_blank" 
                            onClick={() => {
                                window.document.location.href = "https://docs.google.com/document/d/e/2PACX-1vQ_LhJc1plSudhMXGunUED2q6NsmLSjohI4PSqw-Sw9fDIANnujsNDJ5niGofQ1ow/pub?embedded=true";
                            }}
                        />
                </div>
            </div>
            </>
        )
    };


    return (
        <div className='dashboard_main_content_container_style'>            
            {window.innerWidth < 768 && !selectedSyllabi && !selectedGenre ? renderMainContentMobileView() : null}
            
            { selectedTitle === 'library'
                ? librarySwitch()
                : accountStudioSwitch()
            }
        </div>
    )
}
export default DashboardMainContent;