export interface IconBaseProps {
  onClick?: () => void;
  className?: string;
  width?: number;
  height?: number;
}

interface Props extends IconBaseProps {}

function DoubleArrowRight({ className, onClick }: Props) {
  return (
    <div
      className={
        `${
          onClick
            ? "cursor-pointer hover:scale-110 hover:rotate-3 transition-all duration-300 "
            : " "
        }` + className
      }
      onClick={onClick}
    >
      <svg
        id="double_arrow_right"
        data-name="double arrow right"
        xmlns="http://www.w3.org/2000/svg"
        width="8.611"
        height="13.333"
        viewBox="0 0 8.611 13.333"
      >
        <path
          id="solid_angle-double-right"
          data-name="solid angle-double-right"
          d="M41.07,101.912,35.4,96.244a1,1,0,0,0-1.413,0l-.942.942a1,1,0,0,0,0,1.413l4.018,4.018-4.018,4.018a1,1,0,0,0,0,1.413l.942.942a1,1,0,0,0,1.413,0l5.668-5.669A.991.991,0,0,0,41.07,101.912Z"
          transform="translate(-32.753 -95.95)"
        />
      </svg>
    </div>
  );
}

export default DoubleArrowRight;
