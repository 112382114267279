import LargeCard from '../LargeCard/LargeCard'
import useDashboardState from '../../utils/useDashboardContext'
import './SyllabiList.css';

const SyllabiList = ({ activeInfo }) => {

    const { state } = useDashboardState()
    const { selectedSyllabi } = state

    if (selectedSyllabi.lessons === 'No Videos') return ''

    return (
        <div className='syllabi_list_divider_line'>
            <span className='syllabi_list_container'>
                {selectedSyllabi && 
                    selectedSyllabi?.lessons.map((lesson, index) => {
                        return <LargeCard 
                        lesson={lesson} 
                        genre={selectedSyllabi.genre} 
                        infoOff={activeInfo} 
                        key={index} />
                    })
                }
            </span>
        </div>
    )
}

export default SyllabiList;