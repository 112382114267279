import { useState } from 'react'
import DoubleArrowRight from '../../assets/Icons/DoubleArrowRight';
import AtodModal from '../AtodModal'
import './AtodTab.css';

interface Props {
    tabName: string;
    activeTab: string;
    setActiveTab: (tabName: string) => void;
    className?: string;
}

const AtodTab = ({ tabName, activeTab, setActiveTab, className }: Props) => {

    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const handleClick = (e: any )=> {
        e.preventDefault()
        setActiveTab(tabName)
        tabName === 'Terms & Conditions' && handleOpen()
    }

    return (
        <>
            <div className={`tab_container_style  ${className}`} onClick={handleClick} style={{background: activeTab === tabName ? '#D7DFF4' : ''}}>
                <p className={`title_tab ${className}`}>{tabName}</p>
                <DoubleArrowRight className={`arrow_style ${className}`} />
            </div>
            <AtodModal open={open} onClose={handleClose} modalBtn='terms_condition'/>
        </>
    )
}

export default AtodTab;
