import MyLibrary from '../../pages/MyLibrary/MyLibrary'
import Studio from '../../pages/Studio/Studio'
import Account from '../../pages/Account/Account'
import useDashboardState from '../../utils/useDashboardContext'
import './DashboardContentLists.css'

const DashboardContentLists = () => {

    const { state } = useDashboardState()
    const { selectedTitle } = state

    return (
        <div className="containerSize">
            {selectedTitle === 'library' && <MyLibrary />}
            {selectedTitle === 'studio' && <Studio />}
            {selectedTitle === 'user' && <Account />}
        </div>
    )
}

export default DashboardContentLists
