import React from 'react'
import { styled } from '@mui/material'
import { Button } from '@mui/material'
import { CircularProgress } from '@mui/material'

export default function AtodButton(props) {

    const BootstrapButton = styled(Button)(({ theme }) => ({
        color: props.color ?? '#FFFFFF',
        height: props.height ?? '50px',
        fontSize: props.fontSize ?? '16px',
        fontFamily: 'DMSans',
        backgroundColor: props.bg ?? '#005FC5',
        border: props.border ?? 'none',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        textTransform: 'none',
        boxShadow: 'none',
        marginTop: props.marginBottom ?? '10px',
        margin: props.margin ?? '0',
        width: props.width ?? '200px',
        position: props.position ?? 'none',
        bottom: props.bottom ?? 'none',
        top: props.top ?? 'none',
        '&:hover': {
            background: props.hc ?? '#005FC5',
         },

         '@media (max-width: 768px)': {
            width: '140px',
            height: '55px',
            fontSize: '14px',
         },

         '@media (max-width: 425px)': {
            width: '100px',
            height: '30px',
            fontSize: '9px',
         }
    }))

    return (
        <BootstrapButton variant='contained' onClick={!props.isLoading ? props.onClick : null} disabled={props.disabled ?? false}>
        {props.isLoading 
            ? <CircularProgress color='inherit' size='25px' /> 
            : props.text
        }
        </BootstrapButton>
    )  
}