import AtodLogo from '../../assets/Images/AtodLogo.png'
import { ReactComponent as Library } from '../../assets/Icons/Library.svg'
import { ReactComponent as Studio } from '../../assets/Icons/GraduationHat.svg'
import { ReactComponent as User } from '../../assets/Icons/User.svg'
import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'
import { useHistory } from 'react-router'
import useTeacherState from '../../utils/useTeacherContext'
import { baseURL } from '../../utils/axios'

const Sidebar = ({ page }) => {
    const { teacherState } = useTeacherState()
    const history = useHistory()

    const linkToPage = (page) => {
        history.push({ pathname: `/${page}`})
    }

    return (
        <div className='sidebar_container'>
            <div className='sidebar_icons_group'>
                <img src={AtodLogo} alt='Atod Logo' className='sidebar_atod_logo'/>
                <Library className='sidebar_icons' style={{ marginTop: '20px', opacity: page === 'Dashboard' && 1 }} id='library' onClick={() => linkToPage('dashboard')}/>
                <Studio className='sidebar_icons' style={{ marginTop: '20px', opacity: page === 'Studio' && 1 }} id='studio' onClick={() => linkToPage('studio')}/>
                <User className='sidebar_icons' style={{ marginTop: '20px', opacity: page === 'Account' && 1 }} id='user' onClick={() => linkToPage('user')}/>
            </div>

            <div className='avatar'>
                <Stack direction="row" className='sidebar_user_icon'>
                    <Avatar className='sidebar_user_avatar' sx={{background: '#F5D900'}} alt="L" src={baseURL+'file/aws_download?filename='+teacherState.teacher.avatar} />
                </Stack>
            </div>
        </div>
    )
}

export default Sidebar;
