import './GenreCard.css'
import { styled } from '@mui/material'
import { Button } from '@mui/material'
import { GenreColorGenerator } from '../../utils/GenreColorGenerator'

const GenreCard = ({ pack, handleClick, selected, disabled }) => {

  const genreColor = pack.genre && GenreColorGenerator(pack.genre)
  
  const cardStyle = {
    borderStyle: {
      width: '70%',
      borderBottom: '1px solid #E3E3E3',
      margin: '0.5vw 0 0.5vw 1vw',
    },
    containerStyle: {
      boxShadow: pack.genre === 'Full Suite' 
      ? '5px 5px 5px 1px #DEDEDE'
      : `inset 8px 0px 0px 0px ${genreColor}, 5px 5px 5px 1px #DEDEDE`
    }
  }

  const BootstrapGenreButton = styled(Button)(({ theme }) => ({
    width: '40%',
    height: '2.3em',
    fontSize:' 0.7vw',
    textTransform: 'none',
    margin: 'auto 0',
    marginLeft:' 1.5em',

    '@media (max-width: 768px)': {
      fontSize:' 0.9vw',
    },

   '@media (max-width: 425px)': {
      fontSize: '1.7vw',
   }
  }));
  
  return(
    <div 
      className={pack.genre === 'Full Suite' ? 'shakeFullSuite borderColor boxStyle' : 'boxStyle'}
      style={cardStyle.containerStyle}
      key={pack.genre}
    >
      <div className='titleStyle'>{pack.genre}</div>
      <div className='card_desc_box'>
       {pack.genre === 'Full Suite'
        ? <p className='card_desc'>Access to all genre Teachers Notes, Videos and Music</p>
        : <p className='card_desc'>Access to that genre only Teachers Notes, Videos and Music</p>
       }
      </div>
      <div style={cardStyle.borderStyle}></div>
      <div className='cardInfo'>
        <div className='price_container'>
          <div className={`packPrice ${disabled && pack.genre !== 'Full Suite' && 'Genre__Card__Slashed__Price'}`}>${pack.price}</div>
          <div className='perMonth'>Per Month</div>
        </div>
        <BootstrapGenreButton 
          variant={selected ? 'contained' : 'outlined'}
          style={{border: !selected && '2px solid', fontWeight: !selected && 'bold',}}
          onClick={handleClick}
          disabled={disabled && pack.genre !== 'Full Suite'}
        >
          {!selected ? 'Add' : 'Remove'}
        </BootstrapGenreButton>
      </div>
    </div>
  )
}

export default GenreCard