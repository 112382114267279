import { useState } from 'react'
import './Subscription.css'
import AtodButton from '../AtodButton'
import AtodModal from '../AtodModal'
import { Alert } from '@mui/material'
import { CardElement } from '@stripe/react-stripe-js'
import useTeacherState from '../../utils/useTeacherContext'
import './PaymentMethod.css';

const PaymentMethod = ({ button, errorMessage, succeededMessage, activeCardDetail, activeCardName }) => {
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const { teacherState } = useTeacherState()

    const CARD_ELEMENT_OPTIONS = {
        hidePostalCode: true,
        style: {
          base: {
            color: "#32325d",
            fontSmoothing: "antialiased",
            fontSize: "3.7vw",
            "::placeholder": {
              color: "#aab7c4",
            },
          },
          invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
          },
        },
        rules: {
            '.Input': {
                width: '6em',
                fontSize: "3em",
            },
        },
    }

    const handleCardHolder = e => {
        activeCardName(e.target.value)
    }

    return(
        <div>
            {button
            ?   <>
                    <div className='payment_method_subsc_setting'>
                        <div className='payment_method_card_title'>Card</div>
                        <div className='payment_method_card_detail'>{teacherState.teacher.customerId ? 'xxxx xxxx xxxx xxxx' : ''}</div>
                    </div>
                    <AtodButton 
                        text={teacherState.teacher.customerId ? 'Update Details' : 'Add Details'} 
                        bg='#EAEAEA' 
                        hc='#EAEAEA' 
                        color='#375FC5' 
                        border='2px solid #375FC5' 
                        margin='10px 0'
                        onClick={handleOpen}
                    />
                    <AtodModal 
                        open={open}
                        onClose={handleClose}
                        modalBtn='creditCard'
                    />
                </>
            :   <div style={{width: '24vw'}}>
                    <input className='payment_method_cardholder' placeholder='Card Holder Name' onChange={handleCardHolder}/>
                    <CardElement 
                        options={CARD_ELEMENT_OPTIONS}
                        id='stripe_card_element'
                        onChange={e => activeCardDetail(e)}
                    />
                    {errorMessage && <Alert severity='error' sx={{padding: '0 1em'}}>{errorMessage}</Alert>}
                    {succeededMessage && <Alert severity='success' sx={{padding: '0 1em', marginTop: '0.3em'}}>{succeededMessage}</Alert>}
                </div>
            }
        </div>
    )
}

export default PaymentMethod;