import { useEffect, useState } from 'react'
import AtodButton from '../../components/AtodButton'
import AtodModal from '../../components/AtodModal'
import PaymentMethod from '../../components/Subscription/PaymentMethod'
import StudioPlan from '../../components/Subscription/StudioPlan'
import SubscPeriod from '../../components/Subscription/SubscPeriod'
import { Discount } from '../../assets/Constant/Discounts'
import Instance from '../../utils/axios'
import { discountTypeConverter, reverseTypeConverter } from '../../utils/discountTypeConverter'
import useTeacherState from '../../utils/useTeacherContext'
import { DefaultGenrePacks } from '../../assets/Constant/GenrePacks';
import './SubscriptionSettings.css';

const SubscriptionSettings = () => {
    const [ open, setOpen ] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const [ isLoading, setIsLoading ] = useState(false)

    const [ discount, setDiscount ] = useState(Discount[0])
    const [ selectedGenrePacks, setSelectedGenrePacks ] = useState([])
    const { teacherState } = useTeacherState()
    const { teacher } = teacherState

    const changePeriod = async () => {
        setIsLoading(true)
        try {
            const result = await Instance.post('/teacherStripe/changePeriod', {
                type: discountTypeConverter(discount)
            })
            console.log(result)
        } catch (error) {
            console.log(error.response)
        } finally {
            setIsLoading(false)
        }
    }

    const checkSelectedGenres = () => {
        if (teacher?.subscription || teacher?.dance?.includes('full')) {
            DefaultGenrePacks.map(genre => setSelectedGenrePacks(prev => [...prev, genre]))
        } else {
            const genres = DefaultGenrePacks.filter(genre => teacher?.dance?.indexOf(genre.genre)>-1)
            setSelectedGenrePacks(genres)
        }
    }

    useEffect(() => {
        if (teacher?.type) {
            const selectType = reverseTypeConverter(teacher.type)
            const subscType = Discount.find(type => type.period === selectType)
            setDiscount(subscType)
        }
        checkSelectedGenres()
    },[teacher])

    return (
        <div className='subscription_settings_main_container'>
            <div className='subscription_settings_block_container'>
                <div className='subscription_settings_main_title_style'>Subscription Settings</div>
                    <h3 className='subscription_settings_title_style'>Payment</h3>
                    <p className='subscription_settings_desc_style'>Your current ATOD@Work payment method</p>
                    <PaymentMethod button='necessary'/>
                <div className='subscription_settings_border_style'></div>
                    <h4 className='subscription_settings_title_style'>Subscription billing period</h4>
                    <p className='subscription_settings_desc_style'>Select when you would like your subscription to be billed.</p>
                    <SubscPeriod discount={discount} setDiscount={setDiscount}/>
                    <AtodButton 
                        text='Save' 
                        bg='#EAEAEA' 
                        hc='#EAEAEA' 
                        color='#375FC5' 
                        border='2px solid #375FC5' 
                        margin='10px 0'
                        onClick={changePeriod}
                        isLoading={isLoading}
                    />
                <div className='subscription_settings_border_style'></div>
                    <h4 className='subscription_settings_title_style'>Cancel Subscription</h4>
                    <p className='subscription_settings_desc_style'>Cancel your current ATOD On the web subscription.</p>
                    <AtodButton 
                        text='Cancel Subscription' 
                        width='300px' 
                        height='45px' 
                        bg='#C71C27' 
                        hc='#C71C27' 
                        padding='5px' 
                        color='#FFFFFF' 
                        margin='10px 0' 
                        onClick={handleOpen}
                    />
                    <AtodModal 
                        open={open}
                        onClose={handleClose}
                        modalBtn='cancel'
                    />
            </div>
            <div className='subscription_settings_plan_container'>
                <h3 className='subscription_settings_plan_title'>Your Studio Plan</h3>
                <div className='subscription_settings_plan_box'>
                    <StudioPlan selectedPacks={selectedGenrePacks} discount={discount}/>
                </div>
            </div>
        </div>
    )
}

export default SubscriptionSettings;