import React, { useState, useEffect, useRef } from 'react'
import './VideoPlayer.css'
import ReactPlayer from 'react-player'
import FlipVideoController from './VideoController/FlipVideoController'
import PlaybackRate from './VideoController/PlaybackRate'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import screenfull from 'screenfull'
import { AiFillSetting } from 'react-icons/ai'

const VideoPlayer = ({ url }) => {

  const [ playing, setPlaying ] = useState(false)
  const [ currentTime, setCurrentTime ] = useState(0)
  const [ duration, setDuration ] = useState(0)
  const [ playbackRate, setPlaybackRate] = useState(1.0)
  const [ flipped, setFlipped ] = useState('Flip - off')
  const [ isSetting, setIsSetting ] = useState(false)
  
  const videoPlayer = useRef(null)
  const progressBar = useRef(null)
  const loadBar = useRef(null)
  const playerContainerRef = useRef(null)

  useEffect(() => {
    progressBar.current.max = duration
  }, [duration])
  
  const handleProgress = ({ played, loaded, playedSeconds }) => {
    setCurrentTime(playedSeconds)
    changeProgressBarTime(played)
    changeLoadBarTime(loaded)
    progressBar.current.value = currentTime
  }

  const togglePlayPause = () => {
    setPlaying(!playing)
  }

  const handleDuration = (seconds) => {
    setDuration(seconds)
  }

  const changeProgress = (e) => {
    setCurrentTime(e?.target?.value ?? 0.0001)
    changeProgressBarTime()
    videoPlayer.current.seekTo(e?.target?.value ?? 0.0001)
  }

  const changeProgressBarTime = (played) => {
    progressBar.current.style.setProperty(
      '--played-width', 
      played
      ?  `${Math.round((played * 100) * 10) / 10}%`
      : `${progressBar.current.value / duration * 100}%`
      )
  }

  const changeLoadBarTime = (loaded) => {
    loadBar.current.style.setProperty('--loaded-width', `${Math.floor(loaded * 100)}%`)
  }

  const handlePlaybackRateChange = (rate) => {
    setPlaybackRate(rate)
  }

  const toggleFullScreen = () => {
    screenfull.toggle(playerContainerRef.current)
  }

  const handleFlipVideo = (select) => {
    setFlipped(select)
  }

  const calculateTime = (secs) => {
    const minutes = Math.floor(secs / 60)
    const seconds = Math.floor(secs % 60)
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`
    return `${minutes}:${returnedSeconds}`
  }

  const handleSettings = () => {
    setIsSetting(!isSetting)
  }

  useEffect(() => {
    setPlaying(false)
    changeProgress()
  },[url])

  const videoStyle = {
    player: {
      position: 'absolute',
      top: '0',
      left: '0',
      borderRadius: '10px',
      overflow: 'hidden',
      transform: flipped === 'Flip video on' ? 'rotateY(180deg)' : null
    }
  }

  return(
    <div className='wrapper' ref={playerContainerRef}>
      <ReactPlayer
        style={videoStyle.player}
        controls={true}
        loop={false}
        muted={false}
        url={url}
        width= '100%'
        height= '100%'
        playing={playing}
        ref={videoPlayer}
        onProgress={handleProgress}
        onDuration={handleDuration}
        playbackRate={playbackRate}
        config={{ file: { attributes: { controlsList: 'nodownload' } } }}
      />
      <div className={isSetting ? 'videoControllerBox' : 'videoControllerBox setting_disabled'}>
        <FlipVideoController 
          flipVideo={flipped}
          onFlipped={handleFlipVideo}
        />
        <PlaybackRate 
          playbackRate={playbackRate} 
          onPlaybackRateChange={handlePlaybackRateChange}
        />
      </div>
      <div className='controllerBox'>
        <div className='timeBox'>
          <p className='playTime'>{calculateTime(currentTime) ?? '0:00'}</p>
          <p className='playTime'>/</p>
          <p className='playTime'>{calculateTime(duration) ?? '0:00'}</p>
        </div>
        <div className='videoPlayPause' onClick={togglePlayPause}>
          {playing
          ? <PauseIcon />
          : <PlayArrowIcon/>
          }
        </div>
        <div className='videoProgressBarBox'>
          <div className='loadedBar' ref={loadBar}></div>
          <input 
            type="range" 
            className='videoProgressBar' 
            defaultValue="0" 
            ref={progressBar}
            onChange={changeProgress} />
        </div>
        <FullscreenIcon className='screenfull' onClick={toggleFullScreen}/>
        <AiFillSetting className='setting' onClick={handleSettings}/>
      </div>
    </div>
  )
}

export default VideoPlayer