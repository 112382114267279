import { useState } from 'react'
import { ReactComponent as Genre } from '../../assets/Icons/Genre.svg'
import { ReactComponent as Level } from '../../assets/Icons/Level.svg'
import useDashboardState from '../../utils/useDashboardContext'
import { GenreColorGenerator } from '../../utils/GenreColorGenerator'
import { getOnlineImage } from '../../utils/getOnlineImage'
import Instance from '../../utils/axios'
import Skeleton from '@mui/material/Skeleton'
import Ballet from '../../assets/Images/Ballet.jpg'
import Contemporary from '../../assets/Images/Contemporary.jpg'
import HipHop from '../../assets/Images/Hip-Hop.jpg'
import Jazz from '../../assets/Images/Jazz.jpg'
import Musical from '../../assets/Images/Musical.jpg'
import NationalCharacter from '../../assets/Images/NationalCharacter.png'
import StreetBeat from '../../assets/Images/StreetBeat.jpg'
import Tap from '../../assets/Images/Tap.jpg'
import './SmallCard.css';
import DoubleArrowRight from '../../assets/Icons/DoubleArrowRight'


const SmallCard = ({ syllabi, genre, id }) => {

    const { dispatch } = useDashboardState()
    const genreColor = (genre && GenreColorGenerator(genre.title)) || (syllabi && GenreColorGenerator(syllabi.d_name))
    const [ isLoading, setIsLoading ] = useState(false)

    const clickSyllabiCard = async () => {
        setIsLoading(true)
        if (id === 'syllabi' && syllabi) {
            console.log(syllabi)
            try {
                const result = await Instance.get(`/dance/getVideo?d_id=${syllabi.d_id}`)
                if (result.status === 200) {
                    const lessons =  result.data.map(({ audio, d_id, notes, v_address, v_id, v_image, v_name, video2, video3, video4 }) => {
                        return { audio, d_id, notes, v_address, v_id, v_image, v_name, video2, video3, video4 }
                    })
                    dispatch({
                        type: 'setSelectedSyllabi', 
                        data: {
                            id: syllabi.d_id, 
                            title: syllabi.d_syllabus_name, 
                            genre: syllabi.d_name, 
                            level: syllabi.d_syllabus_level, 
                            image: syllabi.d_syllabus_image,
                            description: syllabi.d_syllabus_description,
                            favourite: true,
                            lessons: [...lessons]
                        }})
                    dispatch({type: 'setSelectedGenre', data: ''})
                    dispatch({type: 'setSelectedLesson', data: ''})
                    setIsLoading(false)
                }
            } catch (error) {
                console.log(error)
            }
        } else if(id === 'genre' && genre) {
            dispatch({
                type: 'setSelectedGenre', 
                data: {
                    title: genre.title, 
                    image: genre.image, 
                    availability: genre.availability
                }})
            dispatch({type: 'setSelectedSyllabi', data: ''})
            dispatch({type: 'setSelectedLesson', data: ''})
            setIsLoading(false)
        } else {
            return 'default'
        }
        setIsLoading(false)
    }

    const renderGenreImage = () => {
        if (!genre) return
        switch (genre.title) {
            case 'Jazz':
                return Jazz
            case 'Hip-Hop':
                return HipHop
            case 'Contemporary':
                return Contemporary
            case 'Classical Ballet':
                return Ballet
            case 'Musical Theatre':
                return Musical
            case 'Street Beat Tap':
                return StreetBeat
            case 'Tap':
                return Tap
            case 'National Character':
                return NationalCharacter
        }
    }

    return (
        <div className='small_card_container' onClick={clickSyllabiCard} style={{boxShadow: `-6px 0px 0px 0px ${genreColor}`}}>
            <div style={{background: !isLoading && 'lightblue'}} className='small_card_img_box'>
                {isLoading && genre
                ? <Skeleton variant='rectangular' animation='wave' className='small_card_img'/>
                : syllabi 
                    ? <img className='small_card_img' src={getOnlineImage(syllabi? syllabi?.d_image : genre?.image)} alt='title' />
                    : <img className='small_card_img' src={renderGenreImage()} alt='title' />
                }
            </div>

            {syllabi && 
                <div style={{width: '75%'}}>
                    <div className='small_card_title_style'>
                        {syllabi.d_syllabus_name}
                    </div>
                    <div className='small_card_genre_level_style'>
                        <div className='small_card_genre_level_box'>
                            <div className='small_card_icon_box'>
                                <Genre className='small_card_icon_style' style={{fill: genreColor}}/>
                            </div>
                            <p className='small_card_icon_text'>{syllabi.d_name}</p>
                        </div>
                        <div className='small_card_genre_level_box'>
                            <Level className='small_card_icon_style' style={{fill: genreColor}}/>
                            <p className='small_card_icon_text'>{syllabi.d_syllabus_level}</p>
                        </div>
                    </div>
                </div>
            }

            {genre &&
                <div style={{width: '75%'}}>
                    <div className='small_card_title_style'>
                        <p className='small_card_genre_card_text'>{genre.title}</p>
                    </div>
                    <div 
                        className='small_card_genre_card_availability'
                        style={{color: genre.availability === 'Available' ? '#375FC5' : '#E8323E'}}
                    >
                        {genre.availability}
                    </div>
                </div>
            }
            <div className='small_card_arrow_container'>
                <DoubleArrowRight style={{fill: genreColor}} className='small_card_arrow_style'/>
            </div>
        </div>
    )
}

export default SmallCard
