import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';

type Anchor = 'left' | 'top' | 'bottom' | 'right';

interface Props {
    children: React.ReactNode;
    className?: string;
    anchor: Anchor;
    setIsOpen: (isOpen: boolean) => void;
    isOpen?: boolean;
}

export default function ResponsiveDrawer({ children, className, anchor, setIsOpen, isOpen }: Props) {

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

       setIsOpen(open);
    };

  return (
    <div className={className}>
      {([anchor] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>{<MenuIcon />}</Button>
        
          <Drawer
            anchor={anchor}
            open={isOpen}
            className='drawer'
            onClose={() => setIsOpen(false)}
          >
            {children}
           
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}