import './AtodUpdatePasswordImg.css'

const AtodUpdatePasswordImg = (props) => {

  return(
      <div className='container'>
        <img className='imgStyle' src={props.img} alt='ATOD Logo'/>
      </div>
  )
}

export default AtodUpdatePasswordImg