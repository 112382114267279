export default function DashboardReducer(state, action) {
    switch (action.type) {
        case 'setSelectedTitle': {
            return {
                ...state,
                selectedTitle: action.data
            }
        }
        case 'setSelectedGenre': {
            return {
                ...state,
                selectedGenre: action.data
            }
        }
        case 'setSelectedSyllabi': {
            return {
                ...state,
                selectedSyllabi: action.data
            }
        }
        case 'setSelectedLesson': {
            return {
                ...state,
                selectedLesson: action.data
            }
        }
        case 'setSelectedAccountStudio': {
            return {
                ...state,
                selectedAccountStudio: action.data
            }
        }
        default:
            return state
    }
}