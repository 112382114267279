import React from 'react'
import AtodTab from '../AtodTab/AtodTab'
import './SelectList.css';

interface Props {
    tabNames: string[];
    title: string;
    activeTab: string;
    setActiveTab: (tabName: string) => void;
    children?: React.ReactNode;
    className?: string;
}

const SelectList = ({ tabNames, title, activeTab, setActiveTab, children }: Props) => {

    return (
        <div className='select_list_tab_container'>
            <div className='select_list_title_style'>
                {title}
            </div>
            <div className='select_list_tab'>
                {tabNames.map((name, index) => 
                    <React.Fragment key={index}>
                        <AtodTab 
                            tabName={name}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            key={index}
                        />
                        { index <= tabNames.length - 2 && <div className='select_list_hr_line'/>}
                    </React.Fragment>
                )}
            </div>

            {children && <div className='select_list_children'>{children}</div> }
            
        </div>
    )
}

export default SelectList
