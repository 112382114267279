export const JazzDescription = () => {
    return (
        <div>
            <div className='genre_descriptions_header'>Description</div>
            <div style={{ marginBottom: '0.5rem' }}>
                Jazz is a continually changing and evolving genre encompassing many styles such as
                Broadway, Musical Theatre and Lyrical to name a few. This innovative system of training
                encompasses all styles from foundation Jazz to the very latest moves. This is the Complete
                Jazz Syllabus!
            </div>
            The Syllabus:
            <ul>
                <li>progresses systematically and builds strength, energy and attack</li>
                <li>is designed to technically develop students to individually reach their full potential,
            whether on a recreational, professional or teaching level</li>
                <li>is designed to enhance individual creativity with student choreography and
            improvisation included in major levels</li>
                <li>includes introductory notes, including history and terminology</li>
                <li>includes extensive teaching notes, including an anatomical glossary and index</li>
                <li>includes the use of a cane, hat, chair, chorus shoes and more!</li>
            </ul>
            The Syllabus was created by the Jazz Moves Core Committee including well known dancers, 
choreographers and educators in Australia.
        </div>
    )
}

export const BalletDescription = () => {
    return (
        <div>
            <div className='genre_descriptions_header'>Description</div>
            <div style={{ marginBottom: '0.5rem' }}>
            This is a comprehensive system of training that progresses systematically and is delightful to teach. The syllabus has been developed and regularly updated by the Classical Ballet Core Committee. This Committee has included well known Classical Ballet Teachers and Examiners including Graduates of The Australian Ballet School and professional dancers including former Principal dancers. Musical accompaniment is by leading pianist Gary Dionysius, then Company Pianist for The Queensland Ballet.
            </div>
            The Syllabus:
            <ul>
                <li>progresses systematically in order to build strength and artistry</li>
                <li>includes comprehensive teaching notes on the technical aspects and attributes of each exercise and step includes the theory of each step</li>
                <li>includes an anatomical approach to explaining the required technique, with a glossary and index of anatomical terms included</li>
                <li>is designed to technically develop students to individually reach their full potential, whether on a recreational, professional or teaching level</li>
            </ul>
        </div>
    )
}

export const ContemporaryDescription = () => {
    return (
        <div>
            <div className='genre_descriptions_header'>Description</div>
            <div style={{ marginBottom: '0.5rem' }}>
            When originally released this system of training was one of very few in the world. This innovative and carefully structured contemporary/modern system of training was created to meet the needs of this growing and popular genre of dance. The core committee for contemporary dance included professional contemporary dancers and educators.
            </div>
            <div style={{ marginBottom: '0.5rem' }}>
            Musical accompaniment is original Music composed by Alisdair Macindoe a well known Australian contemporary dancer and musician.
            </div>
            The Syllabus:
            <ul>
                <li>progresses systematically and is structured for students from 9/10 years of age with classical ballet training background</li>
                <li>provides a solid base for potential future work in dance companies</li>
                <li>supports students’ development in other genres of dance</li>
                <li>includes comprehensive teaching notes on the technical aspects and attributes of each exercise and movement</li>
                <li>incorporates, but is not limited to, warm up, stretches, floorwork, articulation of limbs, turns, travelling and jumps</li>
                <li>is designed to technically develop students to individually reach their full potential, whether on a recreational, professional or teaching level</li>
            </ul>
        </div>
    )
}

export const HipHopDescription = () => {
    return (
        <div>
            <div className='genre_descriptions_header'>Description</div>
            <div style={{ marginBottom: '0.5rem' }}>
            This Hip Hop system of training is an opportunity to expand your student’s training to include up to the minute street dance. There is a focus on correct technique including body placement and alignment together with the need for individuality and personal style in the execution of the work. 
            </div>
            The Syllabus:
            <ul>
                <li>incorporates, but is not limited to, a comprehensive warm-up, stretches and isolations</li>
                <li>encourages individuality, style and improvisation throughout</li>
                <li>allows teachers and students the freedom for personal interpretation of the movements</li>
                <li>progresses systematically and builds strength, energy and dynamics</li>
                <li>will enhance individual creativity with student choreography and improvisation included throughout</li>
                <li>will continually evolve with regular updates to keep abreast of the changing culture that is hip hop.</li>
            </ul>
            <div style={{ marginBottom: '0.5rem' }}>
                This Hip Hop syllabus was created by the ATOD Hip Hop Core Committee. This committee includes well known ATOD Teachers and Examiners together with specialist Hip Hop performers and educators. There are various styles included such as tutting, b-boy/b-girl, krump, popping, locking etc.
            </div>
            Cultural contexts and authenticity is included and encouraged when teaching.
        </div>
    )
}

export const TapDescription = () => {
    return (
        <div>
            <div className='genre_descriptions_header'>Description</div>
            <div style={{ marginBottom: '0.5rem' }}>
            This syllabus is a technically based yet modern tap syllabus. An obvious attribute of the syllabus is that there is a focus on development and continuity throughout the levels. Use of the head, arms, corner and travelling steps provide a superb foundation for the performance work. The core committee has included professional performers, teachers and examiners of ATOD.
            </div>
            <div style={{ marginBottom: '0.5rem' }}>
            This Syllabus is Tap Training at its best.
            </div>
            The Syllabus:
            <ul>
                <li>is technically based yet modern and innovative</li>
                <li>includes comprehensive teaching notes – including anatomy, to assist teachers and students in the technical attributes and execution of all work</li>
                <li>encompasses diverse styles</li>
                <li>is user friendly and promotes structured learning</li>
                <li>incorporates barre, centre, corner work, combinations and routines</li>
                <li>includes teacher’s own choreography or the opportunity to use the set dances provided.</li>
            </ul>
            This work will revive, revitalise and inspire your teaching.
        </div>
    )
}

export const StreetBeatTapDescription = () => {
    return (
        <div>
            <div className='genre_descriptions_header'>Description</div>
            <div style={{ marginBottom: '0.5rem' }}>
            Street Beat Tap system of training was created as an enhancement to the Tap system of training. Over the decades Street Dance has become popular with the emergence of Tap Dogs. In order to study this style you do need to know how to tap.  It is recommended that this system of training be in addition to undertaking the work in our Tap system of training. The core committee included former members of Tap Dogs with a passion for street dance together with educators and examiners of ATOD.
            </div>
            The Syllabus:
            <ul>
                <li>includes comprehensive teaching notes – including anatomy, to assist teachers and students in the technical attributes and execution of all work</li>
                <li>encompasses diverse styles</li>
                <li>is user friendly and promotes structured learning</li>
                <li>includes the use of relevant props such as grates, grills, steps, canes and more</li>
                <li>includes student interaction with one another</li>
                <li>improvisation</li>
            </ul>
        </div>
    )
}

export const NationalCharacterDescription = () => {
    return (
        <div>
            <div className='genre_descriptions_header'>Description</div>
            <div style={{ marginBottom: '0.5rem' }}>
            The main aim of this syllabus is to introduce teachers and students to the amazing culture and history of other countries through dance and music. The syllabus progresses systematically through each grade. It includes building blocks and strategies to teach correct technique and to assist in developing strength, control, knowledge, musicality and artistry. The music has been played by Mary Robinson, Graduate of the Queensland Conservatoire.
            </div>
            The Syllabus:
            <ul>
                <li>includes comprehensive teaching notes to introduce teachers and students to authentic steps from various countries with emphasis on technical awareness and placement</li>
                <li>includes set enchainements and dances of many nationalities</li>
                <li>incorporates partner and pattern work</li>
                <li>includes barre, centre, enchainements and dances</li>
                <li>includes gender specific enchainements</li>
                <li>includes step variations for teacher’s own choreographic purposes.</li>
            </ul>
            The Syllabus was created by the National Character Core Committee, including teachers and examiners of A.T.O.D. The committee also included a former folkloric Artistic Director who toured the world with her Folk company as well as other Character Companies.
        </div>
    )
}

export const MusicalTheatreDescription = () => {
    return (
        <div>
            <div className='genre_descriptions_header'>Description</div>
            <div style={{ marginBottom: '0.5rem' }}>
            Musical Theatres encompasses the genre of Singing, Dancing and Acting and is currently taking the dance industry by storm. To be a ‘triple threat’ is to have a wide variety of training in order to be well placed to work in the industry. Musical Theatre training also gives students an opportunity to blossom with confidence and assurance when speaking or performing in public. The benefits of Musical Theatre training are wide and varied.
            </div>
            
            <div style={{ marginBottom: '0.5rem' }}>
            The Musical Theatre core committee includes Principals of Youth Theatre Companies, industry teachers, choreographers and examiners. Well know musical theatre directors from local theatre companies have been commissioned to assist with selection and recordings of music. Dialogue and story lines were written by a Master of Creative Writing, Publishing and Editing.
            </div>
            The System of Training:
            <ul>
                <li>Progresses through each level and includes comprehensive resources for teachers and students</li>
                <li>Will help to develop skills in the three main disciplines to competently performing in musical theatre</li>
                <li>Is geared to students aged 5 – 6 onwards</li>
                <li>Will ensure students learn theatre craft, terminology, voice production, theatre etiquette and more</li>
            </ul>
        </div>
    )
}