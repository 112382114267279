import { useState } from 'react'
import BackButton from '../BackButton/BackButton'
import MainTitle from '../MainTitle/MainTitle'
import SyllabiDetail from './SyllabiDetail'
import SyllabiList from './SyllabiList'
import useDashboardState from '../../utils/useDashboardContext'
import './SyllabiContents.css';
import SyllabiDescriptionModal from '../SyllabiDescriptionModal/SyllabiDescriptionModal';
import { useHistory } from 'react-router-dom';


const FavouriteSyllabi = () => {
    const [ isInfoOn, setIsInfoOn ] = useState(true)
    const { state, dispatch } = useDashboardState()
    const { selectedSyllabi, selectedLesson } = state
    const [openSyllabiDescriptionModal, setOpenSyllabiDescriptionModal] = useState(false);
    const handleClose = () => setOpenSyllabiDescriptionModal(false);

    const backToGenre = () => {
        console.log('selectedSyllabi', selectedSyllabi)
        dispatch({
            type: 'setSelectedGenre', 
            data: {
                title: selectedSyllabi.genre, 
                image: selectedSyllabi.image, 
                availability: 'Available'
            }})
        dispatch({type: 'setSelectedSyllabi', data: ''})
        dispatch({type: 'setSelectedLesson', data: ''})
    }

    const renderMobileView = () => {
        return (
            <div className='syllabi_content_container'>
                    <div className='syllabi_mobile_view_options'>
                        {openSyllabiDescriptionModal && <SyllabiDescriptionModal 
                        openModal={openSyllabiDescriptionModal} 
                        onClose={handleClose} 
                        />}
                    
                        <>
                            {window.innerWidth < 768 && selectedLesson ? 
                                <SyllabiDetail isInfoOn={isInfoOn}/> 
                            : 
                                <>
                                     <button
                                            className='syllabi_description_button'
                                            onClick={() => setOpenSyllabiDescriptionModal(true)}
                                            >Read Description
                                    </button>

                                    <SyllabiList activeInfo={setIsInfoOn} />
                                </>
                            }
                        </>
                    </div>
            </div>
        )
    };

    const renderDesktopView = () => {
        return (
            <div className='syllabi_content_container'>
                <SyllabiList activeInfo={setIsInfoOn}/>
                <SyllabiDetail isInfoOn={isInfoOn}/>
            </div> 
        )
    };

    return (
        <>
            <BackButton title='Syllabi' onClick={backToGenre}/> 
            <MainTitle isInfoOn={isInfoOn} activeInfo={setIsInfoOn}/>
            {window.innerWidth >= 768 ? renderDesktopView() : renderMobileView()}
        </>
    )
}

export default FavouriteSyllabi
