import { useEffect, useState } from 'react'
import { DefaultGenrePacks } from '../../assets/Constant/GenrePacks'
import './StudioPlan.css';

const StudioPlan = ({ selectedPacks, discount }) => {

  const [totalPrice, setTotalPrice] = useState(0)
  const [priceBeforeDiscount, setPriceBeforeDiscount] = useState(0)

  const calcGenrePacksPrice = () => {
    if (selectedPacks.includes(DefaultGenrePacks[0])) return 50

    return selectedPacks.length * 20
  }

  const calcTotalPriceWithDiscount = () => {
    switch (discount.period) {
      case 'Monthly':   return calcGenrePacksPrice() * ((100 - discount.price) / 100)
      case 'Quarterly': return calcGenrePacksPrice() * ((100 - discount.price) / 100) * 3
      case 'Yearly':    return calcGenrePacksPrice() * ((100 - discount.price) / 100) * 12
      default:          return calcGenrePacksPrice() * ((100 - discount.price) / 100)
    }
  }

  useEffect(() => {
    setPriceBeforeDiscount(calcGenrePacksPrice())
    setTotalPrice(calcTotalPriceWithDiscount())
  }, [selectedPacks, discount])

  
  return (
    <>
      <div className='studio_plan_total_container'>
        <div className='studio_plan_total_title'>Genre Packs</div>
        <div style={{position: 'absolute', right: '0' }} className='studio_plan_total_price'>${priceBeforeDiscount}</div>
      </div>

      {selectedPacks.map(pack => {
        return (
          <div className='genrePack' key={pack.genre}>
            <div className='genre_pack_title_style'>{pack.genre}</div>
            <div className='genrePrice'>${selectedPacks.includes(DefaultGenrePacks[0]) && pack.genre !== 'Full Suite' ? 0 : pack.price }
            </div>
          </div>
        )
      })}

      <div className='studio_plan_total_container'>
        <div className='studio_plan_total_title'>Subscription</div>
        <div className='studio_plan_total_box'>
          <div className='studio_plan_total_price'>-{discount.price}%</div>
        </div>
      </div>

      <div className='genrePack'>
        <div className='studio_plan_period_title'>Total</div>
        <div className='genrePrice'>-{discount.price}%</div>
      </div>

      <div className='borderStyle'></div>

      <div className='studio_plan_total_container'>
        <div style={{color: '#2066B2' }} className='studio_plan_total_title' >Total</div>
        <div className='studio_plan_total_box'>
          <div style={{color: '#1D1D1D' }} className='studio_plan_total_price'>${totalPrice}</div>
          <div className='studio_plan_per_month'>{discount.period}</div>
        </div>
      </div>
    </>
  )
}

export default StudioPlan;