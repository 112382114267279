export const GenreColor = {
    'Jazz':{
        color: '#61009F'
    },
    'Hip-Hop': {
        color: '#2A0045'
    },
    'Contemporary':{
        color: '#93A0FF'
    },
    'Classical Ballet': {
        color: '#2066B2'
    },
    'Musical Theatre':{
        color: '#E8323E'
    },
    'Street Beat Tap': {
        color: '#00430F'
    },
    'Tap':{
        color: '#46A540'
    },
    'National Character': {
        color: '#FFA0D0'
    },
}