import  { useEffect, useState } from 'react'
import './MainTitle.css'
import { ReactComponent as Genre } from '../../assets/Icons/Genre.svg'
import { ReactComponent as Level } from '../../assets/Icons/Level.svg'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined'
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined'
import useDashboardState from '../../utils/useDashboardContext'
import { GenreColorGenerator } from '../../utils/GenreColorGenerator'
import AtodButton from '../AtodButton'
import InfoIcon from '@mui/icons-material/Info'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Instance from '../../utils/axios'
import useTeacherState from '../../utils/useTeacherContext'
import { getOnlineImage } from '../../utils/getOnlineImage'
import { useHistory } from 'react-router-dom'
import { JazzDescription, BalletDescription, HipHopDescription, ContemporaryDescription, MusicalTheatreDescription, StreetBeatTapDescription, TapDescription, NationalCharacterDescription } from '../GenreDescriptions'

const MainTitle = ({ isInfoOn, activeInfo }) => {

    const [ favourite, setFavourite ] = useState(false)
    const { teacherState, teacherDispatch } = useTeacherState()
    const { state, dispatch } = useDashboardState()
    const { selectedSyllabi, selectedGenre } = state
    const genreColor = (selectedSyllabi && GenreColorGenerator(selectedSyllabi.genre)) || (selectedGenre && GenreColorGenerator(selectedGenre.title))
    let availability = selectedGenre?.availability === 'Available'
    const history = useHistory()
    
    const titleStyle = {
        iconStyle: {
            fill: genreColor,
        },
        likeStyle: {
            background: favourite 
            ? '#005FC5'
            : '#FFFFFF',
            border: favourite
            ? 'none'
            : '2px solid #375FC5',
            color: favourite
            ? '#FFFFFF'
            : '#375FC5', 
        },
        infoStyle: {
            background: isInfoOn 
            ? '#005FC5'
            : '#FFFFFF',
            border: isInfoOn
            ? 'none'
            : '2px solid #375FC5',
            color: isInfoOn
            ? '#FFFFFF'
            : '#375FC5', 
        },
    }

    const findIfFavourited = () => {
        if (!teacherState.teacher.favourite) return
        teacherState.teacher.favourite.forEach(dance => {
            if (dance.d_id === selectedSyllabi.id) setFavourite(true)
        })
    }

    useEffect(() => {
        findIfFavourited()
    }, [])

    const addOrRemoveFavourite = async () => {

        try {
            const result = await Instance.post(`teacher/addOrRemoveFavourite`, {
                t_id: teacherState.teacher.id,
                d_id: selectedSyllabi.id
            })

            teacherDispatch({ type: 'setTeacher', data: {...teacherState.teacher, favourite: result.data }})
        } catch (e) {
            console.log(e.response)
        }
        
    }

    const jumpToUpgrade = () => {
        history.push({pathname: 'studio'})
    }

    const renderGenreNotes = () => {
        switch (selectedGenre.title) {
            case 'Jazz':                    return JazzDescription()
            case 'Classical Ballet':        return BalletDescription()
            case 'Hip-Hop':                 return HipHopDescription()
            case 'Contemporary':            return ContemporaryDescription()
            case 'Musical Theatre':         return MusicalTheatreDescription()
            case 'Street Beat Tap':         return StreetBeatTapDescription()
            case 'Tap':                     return TapDescription()
            case 'National Character':      return NationalCharacterDescription() 
        }
    }

    return (
        <div className='main_title_container'>
            <hr className='main_title_hr' style={{border: `2px solid ${genreColor}`}}/>
            <div className='main_title_box'>
                <div className='main_title_image_box'>
                    {
                        selectedSyllabi?.image ||  selectedGenre?.image
                        ? <img className='main_title_image_style' src={getOnlineImage(selectedSyllabi.image ?? selectedGenre.image)} alt='Syllabi' />
                        : <div></div>
                    }
                </div>

                {selectedSyllabi && 
                    <>
                        <div className='syllabi_main_title_box'>
                            <div className='syllabi_main_title_style'>
                                {selectedSyllabi.title}
                            </div>
                            <div className='main_title_genre_level_box'>
                                <div style={{marginRight: '30px'}}>
                                    <Genre className='main_title_icon_style' style={titleStyle.iconStyle}/>
                                    {selectedSyllabi.genre}
                                </div>
                                <div>
                                    <Level className='main_title_icon_style' style={titleStyle.iconStyle}/>
                                    {selectedSyllabi.level}
                                </div>
                            </div>
                        </div>
                        <div className='main_title_icon_box' onClick={() => activeInfo(!isInfoOn)}>
                            {isInfoOn
                                ? <InfoIcon sx={{width: '2.7vw', height: '2.7vw'}} className='main_title_info_style' style={titleStyle.infoStyle}/>
                                : <InfoOutlinedIcon sx={{width: '2.7vw', height: '2.7vw'}} className='main_title_info_style' style={titleStyle.infoStyle}/>
                            }
                        </div>
                        <div className='main_title_icon_box' onClick={() => addOrRemoveFavourite()}>
                            {favourite
                                ? <FavoriteOutlinedIcon sx={{width: '2.7vw', height: '2.7vw'}} className='main_title_like_style' style={titleStyle.likeStyle} />
                                : <FavoriteBorderOutlinedIcon sx={{width: '2.7vw', height: '2.7vw'}} className='main_title_like_style' style={titleStyle.likeStyle}/>
                            }
                        </div>
                    </>
                }
                {selectedGenre &&
                    <>
                        <div className='main_title_genre_title'>
                            <div className='main_title_genre_title_style'>
                                {selectedGenre.title}
                            </div>
                        </div>
                        <div className='main_title_availability_box' >
                            <AtodButton
                                margin='0'
                                position='absolute'
                                top='25%'
                                transform='translateY(-25%)'
                                width='100%'
                                fontSize={availability ? '1.5vw' : '1vw'}
                                padding='15px 0'
                                borderRadius='10px'
                                text={availability ? 'Available' : 'Upgrade To Access'}
                                bg={availability ? '#FFFFFF' : '#005FC5'}
                                hc={availability ? '#FFFFFF' : '#005FC5'}
                                color={availability ? '#005FC5' : '#FFFFFF'}
                                border={availability ? '1px solid #375FC5' : 'none'}
                                updatePacks={!availability && dispatch}
                                onClick={!availability ? jumpToUpgrade() : () => null}
                            />
                        </div>
                    </>
                }
            </div>
            {selectedGenre &&
            <div className='main_title_genre_desc'>
            {renderGenreNotes()}
            </div>
            }
            <hr className='main_title_bottom_hr'/>
        </div>
    )
}

export default MainTitle
