import { useState } from 'react'
import SearchSyllabi from '../../components/SearchSyllabi'
import SmallCard from '../../components/SmallCard/SmallCard'
import FavoriteIcon from '@mui/icons-material/Favorite'
import { DefaultGenres } from '../../assets/Constant/DefaultGenres'
import useTeacherState from '../../utils/useTeacherContext'
import Genre from '../../assets/Icons/Genre'
import './MyLibrary.css';

const MyLibrary = () => {
    const { teacherState } = useTeacherState()
    const [ favouriteSyllabus, setFavouriteSyllabus ] = useState(teacherState.teacher.favourite)

    const accessGenres = () => {
        let newGenres = []
        if (teacherState?.teacher?.subscription || teacherState?.teacher?.dance?.includes('full')) {
            DefaultGenres.map(genre => newGenres.push({...genre, availability: 'Available'}))
        } else {
            DefaultGenres.map(genre => {
                if(teacherState?.teacher?.dance?.indexOf(genre.title) >- 1) return newGenres.push({...genre, availability: 'Available'})
                else return newGenres.push(genre)
            })
        }
        return newGenres
    }

    return (
        <>
            <div className="my_library_title_container">
                <div className="my_library_title_style">
                    My Library
                </div>
                <SearchSyllabi/>
            </div>
            <div className='list_style'>
                <div className='category_title'>
                    <FavoriteIcon className='my_library_icon_style'/>
                    <p className='my_library_title_font'>My Favourite Syllabi’s</p>
                </div>
                {favouriteSyllabus && favouriteSyllabus.length === 0
                    ? <div className="no_fav_syllabi">No favourite syllabi</div>
                    : favouriteSyllabus?.map((syllabi) => {
                        return <SmallCard syllabi={syllabi} id='syllabi' key={syllabi.d_id}/>
                        })
                }
                <div className='category_title' > 
                    <Genre className="my_library_icon_style" />
                    <p className='my_library_title_font'>All Genres</p>
                </div>
                {accessGenres().map((genre) => {
                    return <SmallCard genre={genre} id='genre' key={genre.title}/>
                })}
            </div>
        </>
    )
}

export default MyLibrary;