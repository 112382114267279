import { useState } from 'react'
import MainImg from '../../assets/Images/Main_Img.png'
import BackButton from '../../components/BackButton/BackButton'
import StudioPlan from '../../components/Subscription/StudioPlan'
import GenrePacks from '../../components/GenrePacks/GenrePacks'
import AtodButton from '../../components/AtodButton'
import { useHistory } from 'react-router-dom'
import { DefaultGenrePacks } from '../../assets/Constant/GenrePacks'
import { Discount } from '../../assets/Constant/Discounts'
import Checkout from '../../components/Checkout'

const PickGenrePacks = () => {
    const [selectedGenrePacks, setSelectedGenrePacks] = useState([])
    const [unselectedGenrePacks, setUnselectedGenrePacks] = useState([...DefaultGenrePacks])

    const [checkout, setCheckout] = useState(false)

    const [discount, setDiscount] = useState(Discount[0])
    const history = useHistory()

    const backToPrevPage = () => {
        if (checkout) setCheckout(false)
    }

    const skipCheckout = () => {
        history.push({pathname: 'dashboard'})
    }
    
    return(
        <div style={{display: 'flex', position: 'relative'}}>
            <img width={!checkout ? '20%' : '30%'} img={MainImg}/>
            <div style={!checkout ? packStyle.boxContainer : {...packStyle.boxContainer, width: '40%'}}>
                {checkout && <BackButton smaller='smaller' onClick={() => backToPrevPage()} />}
                <div style={packStyle.mainTitle}>{!checkout? 'Pick Your Packs' : 'A Few Extras'}</div>
                <h3 style={packStyle.sectionTitle}>{!checkout? 'Select your genre packs' : 'Payment'}</h3>
                <p style={packStyle.section_instruction}>
                    {!checkout
                        ? 'Pick the genres you want access to from our ATOD library.'
                        : 'Provide your preferred payment details for you ATOD Studio account.'
                    }</p>
                {!checkout
                    ?   <div >
                            <GenrePacks select={true}
                            selectedPacks={selectedGenrePacks} unselectedPacks={unselectedGenrePacks}
                            setSelectedPacks={setSelectedGenrePacks} setUnselectedPacks={setUnselectedGenrePacks}
                            />
                            <div style={{display: 'flex'}}>
                                <AtodButton onClick={() => setCheckout(true)} text='Continue' margin='20px 0'/>
                                <AtodButton onClick={skipCheckout} text='Skip' bg='#FFFFFF' hc='#FFFFFF' color='#005FC5' margin='20px 15px' border='1px solid #005FC5'/>
                            </div>
                        </div> 
                    :   <Checkout discount={discount} setDiscount={setDiscount} selectedPacks={selectedGenrePacks}/>
                }
            </div>
            <div style={packStyle.borderStyle}></div>
            <div style={packStyle.planBoxStyle}>
                <h3 style={{...packStyle.sectionTitle, marginBottom: '0.5vw'}}>Your Genre Packs</h3>
                <StudioPlan selectedPacks={selectedGenrePacks} discount={discount} />
            </div>
        </div>
    )
}

export default PickGenrePacks

const packStyle = {
    boxContainer: {
        float: 'left',
        paddingTop: '1.2em',
        paddingLeft: '3vw',
        width: '50%',
        height: '100vh',
        overflowY: 'scroll',
        overflowX: 'hidden'
    },
    mainTitle: {
        color: '#2066B2',
        fontFamily: 'DMSans',
        fontWeight: 'bold',
        fontSize: '1.4vw',
    },
    sectionTitle: {
        color: '#2066B2',
        marginTop: '0.8vw',
        fontSize: '1vw',
    },
    section_instruction: {
        color: '#414141',
        fontSize: '0.8vw',
    },
    borderStyle: {
        width: '1px',
        height: '80vh',
        background: '#E3E3E3',
        margin: 'auto 1.5vw',
    },
    planBoxStyle: {
        marginTop: '100px',
        width: '16.2vw'
    },
    titleStyle: {
        fontSize: '1.4em',
        marginTop: '20px',
    },
    descStyle: {
        fontSize: '0.8em',
        marginBottom: '10px',
    },
    trialBox: {
        width: '400px',
        height: '100px',
        background: '#D7DFF4',
        borderRadius: '10px',
        margin: '10px 0',
        textAlign: 'center',
        padding: '10px 80px',
    },
    borderHorizontal: {
        borderBottom: '1px solid #E3E3E3',
        width: '80%',
        margin: '20px auto',
    },
    trialTitle: {
        fontSize: '1.3em',
        fontWeight: 'bold',
        color: '#2066B2'
    },
    trialDesc: {
        color: '#414141'
    }
}