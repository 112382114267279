import { useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import MainImage from '../../assets/Images/Main_Img.png'
import useTeacherState from '../../utils/useTeacherContext'
import AtodModal from '../../components/AtodModal'
import { baseURL } from '../../utils/axios'
import AtodImg from '../../components/AtodImg/AtodImg';
import AtodButton from '../../components/AtodButton';
import './MyAccount.css';

const MyAccount = () => {
    const { teacherState } = useTeacherState()
    const [ open, setOpen ] = useState(false)
    const [ openLogoutModal, setLogoutModalOpen ] = useState(false);
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const handleLogoutModalOpen = () => setLogoutModalOpen(true);
    const handleLogoutModalClose = () => setLogoutModalOpen(false);
    const editNameEmail = () => {
        handleOpen()
    }   

    return (
        <div className='my_account_container'>
            <AtodModal
            open={open}
            onClose={handleClose}
            modalBtn='updateAccount'
            />
            <AtodImg img={MainImage}  className="img_view_my_account" />
            <div className='img_underline' />
            <div className='icon_container'>
                <div className='icon_img'>
                    {teacherState?.teacher?.avatar
                        ? <img src={baseURL+'file/aws_download?filename='+teacherState.teacher.avatar} className='avatar_style' alt='avatar'/>
                        : <div></div>
                    }
                    <EditIcon className='non_edit_icon' onClick={editNameEmail}/>
                </div>
            </div>
            <div className='name_email_box'>
                    <label className='name_email_title'>Name</label>
                    <input className='name_email_input' readOnly={true} defaultValue={teacherState?.teacher?.name}/>
                    <label className='name_email_title'>Email</label>
                    <input className='name_email_input' readOnly={true} defaultValue={teacherState?.teacher?.email}/>
            </div>

            <div className='logout_button_mobile_view'>
                <AtodButton
                    text='Logout'
                    width='90%'
                    bg='#E8323E'
                    hc='#E8323E'
                    margin='0 auto'
                    padding='15px'
                    onClick={handleLogoutModalOpen}
                />
            </div>

            <AtodModal
                open={openLogoutModal}
                onClose={handleLogoutModalClose}
                modalBtn='logout'
            />
        </div>
    )
}

export default MyAccount;