import { useState, useEffect } from 'react'
import AtodButton from '../../components/AtodButton'
import StudioPlan from '../../components/Subscription/StudioPlan'
import GenrePacks from '../../components/GenrePacks/GenrePacks'
import { useHistory } from 'react-router-dom'
import { DefaultGenrePacks } from '../../assets/Constant/GenrePacks'
import { Discount } from '../../assets/Constant/Discounts'
import useTeacherState from '../../utils/useTeacherContext'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Instance from '../../utils/axios'
import { reverseTypeConverter } from '../../utils/discountTypeConverter'
import './StudioGenrePacks.css';

const StudioGenrePacks = () => {
    const { teacherState } = useTeacherState()

    const [ confirmed, setConfirmed ] = useState(false)
    const [ selectedGenrePacks, setSelectedGenrePacks   ] = useState([])
    const [ unselectedGenrePacks, setUnselectedGenrePacks   ] = useState([...DefaultGenrePacks])
    const [ discount, setDiscount   ] = useState(Discount[0])
    const [ initSelectedPacks, setInitSelectedPacks ] = useState([])
    const [ isLoading, setIsLoading ] = useState(false)

    const history = useHistory()

    const updatePacks = () => {
        history.push({ pathname: '/user', state: { startTab: 'Subscription Settings' } })
    }

    useEffect(() => {
        const dances = teacherState.teacher.dance ?? []
        const selectedGenres = teacherState.teacher.subscription ? ['full'] : dances
        if (selectedGenres.length === 0 || !selectedGenres) return
        if (selectedGenres[0] === 'full') {
            setSelectedGenrePacks([...DefaultGenrePacks])
            setUnselectedGenrePacks([])
            setInitSelectedPacks([...DefaultGenrePacks])
        } else {
            const selected = DefaultGenrePacks.filter(title => selectedGenres.indexOf(title.genre) >= 0)
            const unselected = DefaultGenrePacks.filter(title => selectedGenres.indexOf(title.genre) <= -1)
            setSelectedGenrePacks(selected)
            setUnselectedGenrePacks(unselected)
            setInitSelectedPacks(selected)
        }

        if (teacherState?.teacher?.type) {
            const selectType = reverseTypeConverter(teacherState?.teacher?.type)
            const subscType = Discount.find(type => type.period === selectType)
            setDiscount(subscType)
        }

    },[teacherState])
    
    const payGenrePacks = async () => {
        setIsLoading(true)
        const initArray = initSelectedPacks.map(p => p.genre)
        const addedArray = selectedGenrePacks.map(p => p.genre)
        const removedArray = unselectedGenrePacks.map(p => p.genre)
        let added = addedArray.filter(item => initArray.indexOf(item)===-1)
        let removed = removedArray.filter(item => initArray.indexOf(item)>-1)
        try {
            let result
            if (added.includes('Full Suite')) {
                result = await Instance.post('/teacherStripe/changeSubscription', {
                    id: teacherState?.teacher?.id,
                    adding: ['full'],
                    removing: initArray,
                })
            } else if (removed.includes('Full Suite')){
                result = await Instance.post('/teacherStripe/changeSubscription', {
                    id: teacherState?.teacher?.id,
                    adding: addedArray,
                    removing: ['full'],
                })
            } else {
                result = await Instance.post('/teacherStripe/changeSubscription', {
                    id: teacherState?.teacher?.id,
                    adding: added,
                    removing: removed,
                })
            }
            if (result.status === 200) {
                setConfirmed(true)
                setTimeout(() => {
                    history.go(0)
                }, 2000)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    return(
        <div className='studio_genre_packs_wrapper'>

            <div className='titleContainer'>
                <div className='mainTitle'>Studio Genre Packs</div>
                <p className='titleDescription'>Tailor your ATOD@Work experience by editing your Genre Packs.</p>
            </div>

            <div className='mainContainer'>
            {teacherState?.teacher?.customerId
            ? 
                <>
                    <div className='genreBox'>
                        <div className='mainTitle'>Your Packs</div>
                        <GenrePacks 
                        selectedPacks={selectedGenrePacks} unselectedPacks={unselectedGenrePacks}
                        setSelectedPacks={setSelectedGenrePacks} setUnselectedPacks={setUnselectedGenrePacks} 
                        />
                    </div>

                    <div className='studio_genre_packs_plan_box'>
                        <h3 className='studio_genre_packs_plan_box_title_style'>Your Studio Plan</h3>
                        <StudioPlan selectedPacks={selectedGenrePacks} discount={discount}/>
                        <div className='confirmBox'>
                            {
                                confirmed
                                ? <>
                                    <div className='confirmTitle'>Plan Updated</div>
                                    <div className='confirmDesc'>
                                        <p className='textStyle'>Your ATOD Studio plan has been updated.</p>
                                    </div>
                                </>
                                : <>
                                    <div className='confirmTitle'>Confirm Changes</div>
                                    <div className='confirmDesc'>
                                        <p className='textStyle'>Please confirm the changes you have made to your Studio Plan.</p>
                                        <p className='textStyle' style={{ marginBottom: '2em'}}>You will be debited the plan changes upon approval.</p>
                                    </div>
                                    <AtodButton text='Confirm &amp; Pay' margin='0 auto' bottom='0.6em' height='auto' width='auto' fontSize='1vw' onClick={payGenrePacks} isLoading={isLoading}/>
                                </>
                            }
                        </div>
                    </div>
                </>
            :   
                <div className='noAccessContainer'>
                    <div className='updateContainer'>
                        <ArrowForwardIcon className='arrowIcon' />
                        <div className='updateTitle'>Upgrade To Access</div>
                        <div className='updateDesc'>You must have a payment method set to be able to pick your subscription packages.</div>
                        <AtodButton
                            text= 'Payment Details'
                            margin= '1.3em auto'
                            onClick={updatePacks}
                            />
                    </div>
                </div>
            }
            </div>
        </div>
    )
}

export default StudioGenrePacks;